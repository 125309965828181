<template>
  <div class="row">
    <div class="col-xl-12 col-xxl-12 col-sm-12">
      <div
        class="card"
        id="card1"
        style="padding-right: 15px; padding-left: 15px"
      >
        <div class="row">
          <div class="col-lg-12">
            <div class="card-body">
              <form @submit.prevent="submitChurch" method="post">
                <!-- personal User data -->
                <!-- <div v-if="zeroInfoShow"> -->
                <h1 class="text-left mb-2 font-24">Actualizar Dados da Igreja</h1>
                <!-- </div> -->
                <div class="row"></div>

                <div class="row" v-show="firstInfoShow">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Nome*</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="dataChurch.name"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Email*</label>
                      <input
                        type="email"
                        class="datepicker-default form-control"
                        id="datepicker1"
                        v-model="dataChurch.email"
                       />
                    </div>
                  </div>
                  
                  
                    <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto*</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                           maxlength="9"
                           v-model="dataChurch.contact"
                         />
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto Alternativo*</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                           maxlength="9"
                           v-model="dataChurch.alternative_contact"
                         />
                      </div>
                    </div>
                  <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Provincia*</label>
                      <select
                        class="form-control"
                        @change="getDistrictsByprovinceId"
                        v-model="dataChurch.province_id"
                      >
                        <option value="">Selecionar</option>
                        <option
                          :value="p.id"
                          v-for="(p, index) in dataProvinces"
                          :key="index"
                        >
                          {{ p.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Distrito*</label>
                      <select
                        class="form-control"
                        v-model="dataChurch.district_id"
                      >
                        <option value="">Selecionar</option>
                        <option
                          :value="d.id"
                          v-for="(d, index) in dataDistricts"
                          :key="index"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">endereco/Bairro*</label>
                      <input
                        type="text"
                        class="datepicker-default form-control"
                        id="datepicker1"
                        v-model="dataChurch.address_location"
                      />
                    </div>
                  </div>

                
 

                  <div class="col-lg-12 col-md-12 col-sm-12">
                  
                    <button
                      type="submti"
                      class="btn btn-primary float-right"
                      v-if="loading==false"
                     >
                      Subtmer
                    </button>

                       
                    <button
                      type="submti"
                      class="btn btn-success float-right"
                      v-else
                     >
                      Submetendo...
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="block-screen" v-show="loading == true">
    <div class="process">
      <p class="text-center">
        Por favor aguarde enquanto finalizamos o seu cadastro...
      </p>
      <img
        class="img-fluid"
        src="http://localhost:8080/assets/img/processador_3.gif"
        alt=""
      />
    </div>
  </div>
</template>
<script setup>
import store from "@/store";
import toastr from "toastr";
import { onMounted, ref } from "vue";
import ChurchStoreModule from "./ChurchStoreModule";
import Swal from "sweetalert2";
import { useRoute } from "vue-router";

const zeroInfoShow = ref(false);
const firstInfoShow = ref(true);
const secondInfoShow = ref(false);
const stepThreeInfo = ref(false);
const stepFourInfo = ref(false);
const personalData = ref(true);
const loadDistricts = ref(true);
const schoolData = ref(false);
const courseData = ref(false);
const documentData = ref(false);
const loading = ref(false);

const dataProvinces = ref([]);
const dataDistricts = ref([]);

const dataChurch = ref({
    name:'',
    address_location:'',
    email:'',
    contact:'',
    alternative_contact:'',
    district_id:'',
    province_id:''
 });

const disabled = ref(false); 
 const route = useRoute();
 

onMounted(() => {
  getProvinces();
  getChurgch();
  // build_years();
});

async function getChurgch() {
      loading.value = true;

  const resp = await ChurchStoreModule.actions.getChurchById(route.params.id);
  dataChurch.value = resp.data.data[0];
    getdistrictbyProvince(resp.data.data[0].province_id);
    loading.value = false

 }

async function getProvinces(evt) {
  const resp = await ChurchStoreModule.actions.provinces();
  dataProvinces.value = resp.data;
  console.log(resp.data);
}

async function getdistrictbyProvince(id){
   const resp = await ChurchStoreModule.actions.districtsByProvinceId(id);
    dataDistricts.value = resp.data;
}

async function getDistrictsByprovinceId(evt) {
  loading.value = true;
  const resp = await ChurchStoreModule.actions.districtsByProvinceId(
    evt.target.value
  );
  dataDistricts.value = resp.data;

  if (resp.data.length > 0) {
    loading.value = false;
  }
}

function build_years() {
  const yearStart = new Date().getFullYear();
  var years = [];
  var i = 0;

  for (let index = yearStart; index >= 1900; index--) {
    years[i] = index;
    i += 1;
  }
  return years;
}

 
 

async function submitChurch() {
  loading.value = true;


  if (dataChurch.value.name == "" || dataChurch.value.address_location == "" || dataChurch.value.alternative_contact == "" || dataChurch.value.contact == "" || dataChurch.value.email == "") {
      loading.value = false;
      return Swal.fire({
        position: "top-end",
        icon: "info",
        title: 'Por favor preencha os campos obrigatorios',
        showConfirmButton: false,
        timer: 1500,
      });
  }
  const resp = await ChurchStoreModule.actions.UpdateChurch(dataChurch.value, route.params.id);
  if (resp.data.status === true) {
    loading.value = false;
 
     return Swal.fire({
      position: "top-end",
      icon: "success",
      title: resp.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  } else {
    loading.value = false;
    return Swal.fire({
      position: "top-end",
      icon: "error",
      title: resp.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
}

function clearInputs() {
  dataChurch.value.name = "";
  dataChurch.value.address_location = "";
  dataChurch.value.alternative_contact = "";
  dataChurch.value.contact = "";
  dataChurch.value.district_id = "";
  dataChurch.value.email = "";
    
 
}
 
function numberOnly($event) {
  let keycode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keycode < 48 || keycode > 57) && keycode !== 46) {
    $event.preventDefault();
  }
}
</script>