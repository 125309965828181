<template>
  <div class="row">
    <div class="col-xl-12 col-xxl-12 col-sm-12">
      <div
        class="card"
        id="card1"
        style="padding-right: 15px; padding-left: 15px"
      >
        <div class="row">
          <div class="col-lg-12">
            <div class="card-body">
              <form @submit.prevent="submitPosition" method="post">
                <!-- personal User data -->
                <!-- <div v-if="zeroInfoShow"> -->
                <h1 class="text-left mb-2 font-24">Dados da Posicao</h1>
                <!-- </div> -->
                <div class="row"></div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Titulo*</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="dataPostition.title"
                      />
                    </div>
                  </div>
 
                   
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Descricao*</label>
                        <textarea
                        v-model="dataPostition.description"
                          type="text"
                          class="form-control"
                          ></textarea>
                      </div>
                    </div>
                

                
 

                  <div class="col-lg-12 col-md-12 col-sm-12">
                  
                    <button
                      type="submti"
                      class="btn btn-primary float-right"
                      v-if="loading==false"
                     >
                      Subtmer
                    </button>

                       
                    <button
                      type="submti"
                      class="btn btn-success float-right"
                      v-else
                     >
                      Submetendo...
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="block-screen" v-show="loading == true">
    <div class="process">
      <p class="text-center">
        Por favor aguarde enquanto finalizado o seu acesso...
      </p>
      <img
        class="img-fluid"
        src="http://localhost:8080/assets/img/processador_3.gif"
        alt=""
      />
    </div>
  </div>
</template>
<script setup>
import store from "@/store";
import toastr from "toastr";
import { onMounted, ref } from "vue";
import PositionStoreModule from "./PositionStoreModule";
import Swal from "sweetalert2";

  
  
const loading = ref(false);

  
const dataPostition = ref({
  title: "",
  description: "",
  
});

    
async function submitPosition() {
  loading.value = true;

  const resp = await PositionStoreModule.actions.addPostion(dataPostition.value);
  if (resp.data.status === true) {
    loading.value = false;
 
    clearInputs();
    return Swal.fire({
      position: "top-end",
      icon: "success",
      title: resp.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  } else {
    loading.value = false;
    return Swal.fire({
      position: "top-end",
      icon: "error",
      title: resp.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
}

function clearInputs() {
  dataPostition.value.title = "";
  dataPostition.value.description = "";
}
 

function numberOnly($event) {
  let keycode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keycode < 48 || keycode > 57) && keycode !== 46) {
    $event.preventDefault();
  }
}
</script>