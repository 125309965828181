<template>
     <!-- START ROW -->
    
    <div class="row">
            <div class="ml-auto mb-3">
                 <button @click="handleOpenModal" class="btn btn-success float-right">Adicionar Despesa</button>

          </div>
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
            <div class="float-left mb-3 pl-3">

            <div class="row">
                <div  class=" bg-danger text-center  "  style="width:20px; height:20px; border-radius:50%;">
                  <i  class="fa fa-arrow-down text-white "></i>
                </div>
                <div>
                <span>Saidas</span>
                </div>
            
            </div>
         </div>

        
           <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="search"
              />
              <button class="btn btn-success" type="submit"><i class="fa fa-search"></i></button>
              
            
            </div>
            </form>
          </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                     <th scope="col">Descricao</th>
                     <th scope="col">Titulo</th>
                     <th scope="col">Valor</th>
                     <th scope="col">Tipo</th>
                     <th scope="col">Categoria</th>
                     <th scope="col">Data de Criacao</th>
                      </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(e, index) in expenses.data"
                    :key="index"
                   >
                    <td>{{ index + 1 }}</td>
                    <td v-if="e.description == null">-------</td>
                    <td v-else>{{e.description}}</td>
                    <td>{{e.title}}</td>
                    <td>{{moneyFormat(e.amount)}}</td>
                    <td>
                    <div class=" bg-danger text-center justify-items-center align-items-center " style="width:20px; height:20px; border-radius:50%;">
                         <i  class="fa fa-arrow-down text-white "></i>
                    </div>
                    </td>
                    <td>{{e.category_name}}</td>
                    <td>{{formatDates(e.created_at)}}</td>
                    <td>

                     <button class="btn btn-light text-primary mr-2 " data-toggle="dropdown" style="background: #e6f4fb; border-radius:6px">
                            <i class="fa fa-ellipsis-h"></i>
                              <div class="dropdown-menu dropdown-menu-right">
                            
                              <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <h6 class="text-dark m-0"><i class="fa fa-edit mr-2 text-data"></i> <a class="text-primary" style="font-size:12px; text-decoration:none;"> Editar</a></h6>
                              </a>
                               <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <h6 class="text-dark m-0"><i class="fa fa-trash mr-2 text-data"></i> <a href="javascript:void(0)" class="text-primary"  @click="removeProduct(e.id, index)" style="font-size:12px; text-decoration:none;"> Eliminar</a></h6>
                              </a>
                             </div>
                          
                          </button>
                      
                      
                    </td>
 
              
                   </tr>
                </tbody>
              </table>
          
                <Page :total="total_page" :model-value="current_page"  :page-size="parseInt(per_page)" @on-change="getAllExpenses"  v-if="total_page"    class="float-right" />

              <div v-if="expenses.length == 0" class="text-center">
                Ainda sem informação
              </div>
              <div class="text-center" v-if="loadingData">
                <img
                  src="assets/images/loading.gif"
                  width="45"
                  height="45"
                  alt=""
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END ROW -->

    <!-- Modal -->
  
    <div v-show="isExpense"  class="modal " id="fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Adicionar Nova Despesa</h5>
            <button
              type="button"
              @click="handleCloseModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
                  <form @submit.prevent="submitExpenses">
                           
 
                                <div class="row">
                                 <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="col-form-label">Categoria*</label>
                                        <select class="form-control" v-model="expensesData.category_id">
                                            <option :value="c.id" v-for="(c, index) in categories" :key="index">{{c.title}}</option>
                                        </select>
                                    </div>
                                </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label text-black">Titulo*</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="expensesData.title"
                                    />
                                    </div>
                                </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label">Valor*</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="expensesData.amount"
                                    />
                                    </div>
                                </div>
                
                                
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="form-label">Descricao*</label>
                                        <textarea
                                        v-model="expenses.description"
                                        type="text"
                                        class="form-control"
                                        ></textarea>
                                    </div>
                                    </div>
                                

                                
                                </div>
                                  <div class="modal-footer">
                                <button v-if="loading == false"  class="btn btn-success">
                                Submeter
                                </button>
                                <button
                                v-else
                                type="button"
                                class="btn btn-info"
                                >
                                Submetendo...
                                </button>
                            </div>
                            </form>
                     </div>

         
            </div>
        </div>
      </div>  

 

    <!-- Modal end> -->
 </template>

<script setup>
import store from "@/store";
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import ExpensesStoreModule from "./ExpensesStoreModule";

 const expenses = ref([]);
 const expensesCopy = ref([]);
 const loading = ref(false);
 const categories = ref([]);
 const total_page = ref()
 const current_page = ref() 
 const per_page = ref()  
 const isExpense = ref(false)  
 const search = ref(); 

 

 const expensesData = ref({
    'title':'',
    'amount':0,
    'description':'',
    'category_id':0,
    "category_name":''
 })

const router = useRouter();
onMounted(() => {
  getAllExpenses();
  getCategories()
});

function handleOpenModal(){
    isExpense.value = true;
}

function handleCloseModal(){
    isExpense.value = false;

}
 async function getCategories(){

    const resp = await ExpensesStoreModule.actions.getCategories();
    categories.value = resp.data.data;

  }

  async function getAllExpenses(page=1){

    const resp = await ExpensesStoreModule.actions.getAllExpenses(page);
    expenses.value = resp.data;
    total_page.value = resp.data.meta.total 
    current_page.value = resp.data.meta.current_page 
    per_page.value = resp.data.meta.per_page  
    console.log(resp.data);
  }

 async function submitExpenses(){

    loading.value = true;
    

    const resp = await ExpensesStoreModule.actions.addExpenses(expensesData.value);

    const lastExpenses = await ExpensesStoreModule.actions.getLastExpensesInserted();

    expenses.value.data.push(lastExpenses.data.data);

     // console.log(lastExpenses.data.data);
     loading.value = false
 }

  

async function removeProduct(id, index){
     products.value.splice(index, 1);
       const resp = await GroupStoreModule.actions.removeProduct(id);
 

}

 
function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}

  watch(search, (newValue, oldValue)=>{
      if (newValue.length >= 4 || oldValue.length >= 4) {
        getChurches()
      }else if(newValue.length == 0 || oldValue.length == 0){
        getChurches()

      }
  });

 function formatDates(dateFormat){

      const data = new Date(dateFormat);

      const day = data.getDate();
      const month = data.getMonth() + 1;
      const year = data.getFullYear();
      const hours = data.getHours();
      const minutes = data.getMinutes();
      const seconds = data.getSeconds();

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
  }


 

 
</script>



<style scope>
.modal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  transition: height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  border-radius: 10px;

}



.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
 
  width: 100%;

  height: 100%;
}
</style>
