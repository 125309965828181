import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async addAgent(data) {
      try {
        const resp = await axios.post(
          URL_API + "addMember",
          { data },
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async provinces() {
      try {
        const resp = await axios.get(URL_API + "provinces", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async districtsByProvinceId(id) {
      try {
        const resp = await axios.get(URL_API + `district/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getMembers() {
      try {
        const resp = await axios.get(URL_API + `listMembers`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAllMember() {
      try {
        const resp = await axios.get(URL_API + `getAllMember`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async findMember(id) {
      try {
        const resp = await axios.get(URL_API + `findMember/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async updateMember(data, id) {
      try {
        const resp = await axios.put(
          URL_API + `updateMember/${id}`,
          { data },
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAllTotSex() {
      try {
        const resp = await axios.get(URL_API + "getAllTotSex", {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAllMale() {
      try {
        const resp = await axios.put(URL_API + "getAllMale", {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getUserData() {
      try {
        const resp = await axios.get(URL_API + `getUser`, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },
    async updateUser(data) {
      try {
        const resp = await axios.post(URL_API + `updateUser`, data, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async clienteRequireAgents() {
      try {
        const resp = await axios.get(URL_API + `getAgents`);
        return resp;
      } catch (error) {
        return error;
      }
    },
  },
};
