<template>
    <div id="main-app ">
        <router-view></router-view>
    </div>
    </template>
    
    <script>
    export default {
        name: "main-app",
    
        data() {
            return {
                events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
                warningZone: false,
                timeoutInMS: 1800000, // 3 minutes -> 3 * 60 * 1000
                timeoutId: null
            }
        },
       
    };
    </script>
    <style>
    /*div.card-header{
        background: -webkit-linear-gradient( #8f6B29, #FDE08D, #DF9F28);
        background: linear-gradient(#8f6B29, #FDE08D, #DF9F28);
      }*/
    </style>
    