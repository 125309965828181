<template>


        <div class="row">
                <div class="ml-auto pr-2 mb-3"> <button @click="handleOpenModal" class="btn btn-success"> <i class="fa fa-plus"></i> Evento</button></div>
        <div class="col-xl-12">


             <div class="card-body">

                <div v-if="eventData.length > 0" class="row"> 

                <div class="col-sm-6 col-xl-3"  v-for="(e, index) in eventData" :key="index">
                <div class="card">
                  <div class="card-heading p-4">
                    <div class="mini-stat-icon-bell float-right">
                      <i class="fa fa-bell bg-warning text-white" ></i>
                    </div>
                    <div>
                      <h5 class="font-14" style="font-weight:700;">{{e.title}}</h5>
                    </div>
                    <small class="mt-4"  style="font-size:13px"><i class="fa fa-calendar mr-1"></i>{{e.date_start}} ate {{e.date_end}}</small>
                    <div>
                    <small class="mt-4"  style="font-size:13px"><i class="fa fa-clock-o mr-1"></i>{{e.start_at}}</small>
                    </div>
                       <p class="text-muted mt-2 mb-0">
                         {{e.description}}
                        </p>
 
                    

                  <div class="card-foter footer-action">
                     <div class="mr-1">  <i class="fa fa-edit"></i></div> 
                      <div class="mr-1">  <i class="fa fa-trash text-danger"></i></div>
                  </div>
                 
                  </div>
                </div>
              </div>
              </div>


 
                <div v-else class="text-center">
                    <p >Ainda sem eventos</p>
                </div>
            </div>
            </div>
        </div>
        
  
      <!-- Modal -->
  
    <div v-show="isEvent"  class="modal " id="fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><i class="fa fa-bell text-warning"></i> Adicionar Nova Evento</h5>
            <button
              type="button"
              @click="handleCloseModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
                  <form @submit.prevent="submitEvents">
                           
 
                                <div class="row"> 
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label text-black">Titulo*</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="events.title"
                                    />
                                    </div>
                                </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label">Hora*</label>
                                    <input
                                        type="time"
                                        class="form-control"
                                        v-model="events.start_at"
                                    />
                                    </div>
                                </div>


                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label">Data Inicio*</label>
                                    <input
                                        type="date"
                                        class="form-control"
                                        v-model="events.date_start"
                                    />
                                    </div>
                                </div>


                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label">Data Fim*</label>
                                    <input
                                        type="date"
                                        class="form-control"
                                        v-model="events.date_end"
                                    />
                                    </div>
                                </div>
                
                                
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="form-label">Endereco*</label>
                                          <input
                                        type="text"
                                        class="form-control"
                                        v-model="events.address_location"
                                    />
                                    </div>
                                    </div>

                                     <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="form-label">Descricao*</label>
                                        <textarea
                                        v-model="events.description"
                                        type="text"
                                        class="form-control"
                                        ></textarea>
                                    </div>
                                    </div>
                                

                                
                                </div>
                                  <div class="modal-footer">
                                <button v-if="loading == false"  class="btn btn-success">
                                Subtmer
                                </button>
                                <button
                                v-else
                                type="button"
                                class="btn btn-info"
                                >
                                Submetendo...
                                </button>
                            </div>
                            </form>
                     </div>

         
            </div>
        </div>
      </div>  

 

    <!-- Modal end> -->
</template>

<script setup>
import Swal from "sweetalert2";
import { onMounted, ref } from "vue";
 import EventStoreModule from './EventStoreModule'


    const events = ref({
        title:'',
        address_location:'',
        start_at:'',
        date_start:'',
        date_end:'',
        description:''
    });

    const eventData = ref([]);

    const loading = ref(false)
    const isEvent = ref(false) 

    onMounted(()=>{
        getAllEvents()
    });

    async function getAllEvents(){

        const resp = await EventStoreModule.actions.getAllEvents();

        eventData.value = resp.data.data;
    }

    async function submitEvents(){

        loading.value = true
        const resp = await EventStoreModule.actions.addEvent(events.value);

        const lastEvent = await EventStoreModule.actions.getLastEventInserted();
         eventData.value.push(lastEvent.data.data);



         clearInputs()
        if(resp.data.status == true){
            loading.value = false
            return Swal.fire({
            position:'top-end',
            icon:'success',
            title:resp.data.message,
            showConfirmButton:false,
            timer:1500
            })
      }else{
            loading.value = false

          return Swal.fire({
            position:'top-end',
          icon:'error',
          title:resp.data.message,
          showConfirmButton:false,
          timer:1500
        })
     }

    }

    function clearInputs(){
        events.value.address_location = "";
        events.value.date_end = "";
        events.value.date_start = "";
        events.value.description = "";
        events.value.title = ""
        events.value.start_at = ""
    }

    function handleOpenModal(){
    isEvent.value = true;
}

function handleCloseModal(){
    isEvent.value = false;

}
</script>

<style scoped>
    .footer-action{ 
        display: flex;
        flex-direction: row;
        margin-left:auto ;
        width: 40px;
    
    }
     .footer-action div {
        width: 20px;
        height: 20px;
        text-align: center;
     
    }

      .footer-action div i {
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        line-height: 20px;
     }
</style>