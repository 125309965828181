<template>
  <div class="row">
    <div class="col-xl-12 col-xxl-12 col-sm-12">
      <div
        class="card"
        id="card1"
        style="padding-right: 15px; padding-left: 15px"
      >
        <div class="row">
          <div class="col-lg-12">
            <div class="card-body">
              <form @submit.prevent="submitGroup" method="post">
                <!-- personal User data -->
                <!-- <div v-if="zeroInfoShow"> -->
                <h1 class="text-left mb-2 font-24">Dados do Grupo</h1>
                <!-- </div> -->
                <div class="row"></div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Titulo*</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="dataGroup.title"
                      />
                    </div>
                  </div>
 
                   
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Descricao*</label>
                        <textarea
                        v-model="dataGroup.description"
                          type="text"
                          class="form-control"
                          ></textarea>
                      </div>
                    </div>
                

                
 

                  <div class="col-lg-12 col-md-12 col-sm-12">
                  
                    <button
                      type="submti"
                      class="btn btn-primary float-right"
                      v-if="loading==false"
                     >
                      Subtmer
                    </button>

                       
                    <button
                      type="submti"
                      class="btn btn-success float-right"
                      v-else
                     >
                      Submetendo...
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="block-screen" v-show="loading == true">
    <div class="process">
      <p class="text-center">
        Por favor aguarde enquanto finalizado o seu acesso...
      </p>
      <img
        class="img-fluid"
        src="http://localhost:8080/assets/img/processador_3.gif"
        alt=""
      />
    </div>
  </div>
</template>
<script setup>
import store from "@/store";
import toastr from "toastr";
import { onMounted, ref } from "vue";
import GroupStoreModule from "./GroupStoreModule";
import Swal from "sweetalert2";

 const firstInfoShow = ref(true);
 
const loadDistricts = ref(true);
 
const loading = ref(false);

const dataProvinces = ref([]);
const dataDistricts = ref([]);

const dataGroup = ref({
   title: "",
  description: "",
  
});

const disabled = ref(false);
 
const productImg = ref();
const disabledCategory = ref(false);

function ProductCategory(value) {
  if (value.target.value == "Imobiliario") {
    disabled.value = false;
  } else {
    disabled.value = true;
  }
}

onMounted(() => {
  getProvinces();
  // build_years();
});

async function getProvinces(evt) {
  const resp = await GroupStoreModule.actions.provinces();
  dataProvinces.value = resp.data;
  console.log(resp.data);
}

async function getDistrictsByprovinceId(evt) {
  loading.value = true;
  const resp = await GroupStoreModule.actions.districtsByProvinceId(
    evt.target.value
  );
  dataDistricts.value = resp.data;

  if (resp.data.length > 0) {
    loading.value = false;
  }
}

function build_years() {
  const yearStart = new Date().getFullYear();
  var years = [];
  var i = 0;

  for (let index = yearStart; index >= 1900; index--) {
    years[i] = index;
    i += 1;
  }
  return years;
}

 

async function submitGroup() {
  loading.value = true;

  const resp = await GroupStoreModule.actions.addGroup(dataGroup.value);
  if (resp.data.status === true) {
    loading.value = false;
 
    clearInputs();
    return Swal.fire({
      position: "top-end",
      icon: "success",
      title: resp.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  } else {
    loading.value = false;
    return Swal.fire({
      position: "top-end",
      icon: "error",
      title: resp.data.message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
}

function clearInputs() {
   dataGroup.value.title = "";
  dataGroup.value.description = "";

}
 

function numberOnly($event) {
  let keycode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keycode < 48 || keycode > 57) && keycode !== 46) {
    $event.preventDefault();
  }
}
</script>