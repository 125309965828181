<template>
  <div class="container mb-30 bg-white" >
    <div class="row">
        <div class="col-lg-2">
            <a href="/" class="h1 text-uppercase text-primary px-2 btn d-flex align-items-center  bg-white w-100"
             style="height: 65px; padding: 0 10px"
            >
                <img src="https://anucios.jdesign.co.mz/assets/img/sell.svg" class="img-fluid" style="width:50px; height:50px" alt="">
            </a> 
        </div>
      <div class="col-lg-3 d-none d-lg-block ">

        <a 
          v-if="props.role == 'Agent'"
          class="btn d-flex align-items-center justify-content-between bg-white w-100"
          data-toggle="collapse"
          href="#navbar-vertical"
          style="height: 65px; padding: 0 10px"
        >
          <h6 class="text-dark m-0" v-if="path == '/'">
            <!-- <i class="fa fa-bars mr-2"></i> -->
            Categorias
          </h6>
          <h6 class="text-dark m-0" v-else-if="path == '/login'">
            <!-- <i class="fa fa-bars mr-2"></i> -->
            Categorias
          </h6>
          <h6 class="text-dark m-0" v-else-if="path == '/inscription'">
            <!-- <i class="fa fa-bars mr-2"></i> -->
            Categorias
          </h6>
          <h6 class="text-dark m-0" v-else>
            <!-- <i class="fa fa-bars mr-2"></i> -->
            Menu
          </h6>
          <i class="fa fa-angle-down text-dark"></i>
        </a>

        <a
          v-else
          class="btn d-flex align-items-center  bg-white w-100"
          data-toggle="collapse"
          href="#navbar-vertical"
          style="height: 65px; padding: 0 10px"
        >
          <h6 class="text-dark m-0" v-if="path == '/'">
            <!-- <i class="fa fa-bars mr-2"></i> -->
            Categorias
          </h6>
          <h6 class="text-dark m-0" v-else-if="path == '/login'">
            <!-- <i class="fa fa-bars mr-2"></i> -->
            Categorias
          </h6>
          <h6 class="text-dark m-0" v-else-if="path == '/inscription'">
            <!-- <i class="fa fa-bars mr-2"></i> -->
            Categorias
          </h6>
          <h6 class="text-dark m-0" v-else>
            <i class="fa fa-bars mr-2"></i>
            Categorias
          </h6>
          <i class="fa fa-angle-down text-dark ml-2" style="font-weight:700; font-size:12pt"></i>
        </a>

        <nav
          v-if="path == '/'"
          class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light"
          id="navbar-vertical"
          style="width: calc(100% - 30px); z-index: 999"
        >
          <div class="navbar-nav w-100">
            <a href="" class="nav-item nav-link">Imoveis</a>
            <a href="" class="nav-item nav-link">Terrenos</a>
          </div>
        </nav>
        <nav
          v-else-if="path == '/login'"
          class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light"
          id="navbar-vertical"
          style="width: calc(100% - 30px); z-index: 999"
        >
          <div class="navbar-nav w-100">
            <a href="" class="nav-item nav-link">Imoveis</a>
            <a href="" class="nav-item nav-link">Terrenos</a>
          </div>
        </nav>
        <nav
          v-else-if="path == '/inscription'"
          class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light"
          id="navbar-vertical"
          style="width: calc(100% - 30px); z-index: 999"
        >
          <div class="navbar-nav w-100">
            <a href="" class="nav-item nav-link">Imoveis</a>
            <a href="" class="nav-item nav-link">Terrenos</a>
          </div>
        </nav>
        <nav
          v-if="role == 'User'"
          class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light"
          id="navbar-vertical"
          style="width: calc(100% - 30px); z-index: 999"
        >
          <div class="navbar-nav w-100">
            <a href="" class="nav-item nav-link">Imoveis</a>
            <a href="" class="nav-item nav-link">Terrenos</a>
          </div>
        </nav>
        <nav
          v-if="role == 'Agent'"
          class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light"
          id="navbar-vertical"
          style="width: calc(100% - 30px); z-index: 999"
        >
          <div class="navbar-nav w-100">
            <div class="nav-item dropdown dropright">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                >Produtos <i class="fa fa-angle-right float-right mt-1"></i
              ></a>
              <div
                class="dropdown-menu position-absolute rounded-0 border-0 m-0"
              >
                <router-link to="/add-product" class="dropdown-item"
                  >Adicionar</router-link
                >
                <router-link to="/listMyproducts" class="dropdown-item"
                  >Listar</router-link
                >
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div class="col-lg-7">
        <nav class="navbar navbar-expand-lg bg-white navbar-dark py-3 py-lg-0">
          <a href="/" class="text-decoration-none d-block d-lg-none">
            <span class="h1 text-uppercase text-dark bg-light"
              >Anucios</span
            >
            <span class="h1 text-uppercase text-light bg-primary ml-n1"
              >Shop
            </span>
          </a>
          <button
            type="button"
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-between"
            id="navbarCollapse"
          >
            <div class="navbar-nav ml-auto py-0">
              <a href="/" class="nav-item nav-link active">Inicio</a>
              <a href="shop.html" class="nav-item nav-link">Comprar</a>
              <router-link to="/agents" class="nav-item nav-link"
                >Vender</router-link
              >
              <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  >Agencia <i class="fa fa-angle-down mt-1"></i
                ></a>
                <div class="dropdown-menu bg-white rounded-0 border-0 m-0">
                  <a href="cart.html" class="dropdown-item"
                    >Procurar um Agente ou Agencia</a
                  >
                  <a href="checkout.html" class="dropdown-item"
                    >Seja um Agente</a
                  >
                </div>
              </div>

               <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  >Minha Conta <i class="fa fa-angle-down mt-1"></i
                ></a>
                <div class="dropdown-menu bg-white rounded-0 border-0 m-0">
                <a
                      v-if="userLogged == true && role == 'Agent'"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-user mr-2 text-primary"></i>
                        <router-link
                          class="text-primary"
                          to="/profile"
                          style="font-size: 12px; text-decoration: none"
                        >
                          Perfil</router-link
                        >
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>

                    <a
                      v-if="userLogged == true"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-user mr-2 text-primary"></i>
                        <router-link
                          class="text-primary"
                          to="/myData"
                          style="font-size: 12px; text-decoration: none"
                        >
                          Meus Dados</router-link
                        >
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                    <a
                      v-if="userLogged == true"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-lock mr-2 text-primary"></i>
                        <router-link
                          class="text-primary"
                          to="/update_password"
                          style="font-size: 12px; text-decoration: none"
                        >
                          Credencias</router-link
                        >
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                    <router-link
                      class="dropdown-item"
                      v-if="!userLogged"
                      to="/login"
                      type="button"
                      >Entrar</router-link
                    >
                    
                    <a
                      v-if="userLogged == true"
                      @click="logout"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px; padding: 0 10px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-sign-out mr-2 text-danger"></i>
                        <span style="font-size: 12px"> Terminar Sessão</span>
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                </div>



              </div>
                   <!-- <div class="navbar-nav ml-auto py-0 d-none d-lg-block"> -->
                            <router-link to="/myData" class=" nav-item nav-link btn px-0 ml-1" v-if="fullName != null">
                                <i class="fa fa-user  text-success"></i>
                                <span class="badge" style="padding-bottom: 2px;">{{fullName}}</span>
                            </router-link>
                            <a href="" class="nav-item nav-link btn px-0 ml-1">
                                <i class="fa fa-bell text-primary"></i>
                                <span class="badge text-secondary border border-secondary rounded-circle" style="padding-bottom: 2px;">0</span>
                            </a>
            <!-- </div>    -->
            </div>

            <!-- <div class="col-lg-6 text-center text-lg-right">

            
              <div class="d-inline-flex align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-light dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Minha Conta <i class="fa fa-angle-down"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      v-if="userLogged == true && role == 'Agent'"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-user mr-2 text-primary"></i>
                        <router-link
                          class="text-primary"
                          to="/profile"
                          style="font-size: 12px; text-decoration: none"
                        >
                          Perfil</router-link
                        >
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>

                    <a
                      v-if="userLogged == true"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-user mr-2 text-primary"></i>
                        <router-link
                          class="text-primary"
                          to="/myData"
                          style="font-size: 12px; text-decoration: none"
                        >
                          Meus Dados</router-link
                        >
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                    <a
                      v-if="userLogged == true"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-lock mr-2 text-primary"></i>
                        <router-link
                          class="text-primary"
                          to="/update_password"
                          style="font-size: 12px; text-decoration: none"
                        >
                          Credencias</router-link
                        >
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                    <router-link
                      class="dropdown-item"
                      v-if="!userLogged"
                      to="/login"
                      type="button"
                      >Entrar</router-link
                    >
                    
                    <a
                      v-if="userLogged == true"
                      @click="logout"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px; padding: 0 10px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-sign-out mr-2 text-danger"></i>
                        <span style="font-size: 12px"> Terminar Sessão</span>
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="d-inline-flex align-items-center d-block d-lg-none">
                <a href="" class="btn px-0 ml-2">
                  <i class="fas fa-heart text-dark"></i>
                  <span
                    class="badge text-dark border border-dark rounded-circle"
                    style="padding-bottom: 2px"
                    >0</span
                  >
                </a>
                <a href="" class="btn px-0 ml-2">
                  <i class="fas fa-shopping-cart text-dark"></i>
                  <span
                    class="badge text-dark border border-dark rounded-circle"
                    style="padding-bottom: 2px"
                    >0</span
                  >
                </a>
              </div>
            </div>

            <div class="navbar-nav ml-auto py-0 d-none d-lg-block">
                         
                            <router-link to="/myData" class="btn px-0 ml-1" v-if="fullName != null">
                                <i class="fa fa-user  text-success"></i>
                                <span class="badge" style="padding-bottom: 2px;">{{fullName}}</span>
                            </router-link>
                            <a href="" class="btn px-0 ml-1">
                                <i class="fa fa-bell text-primary"></i>
                                <span class="badge text-secondary border border-secondary rounded-circle" style="padding-bottom: 2px;">0</span>
                            </a>
            </div>   -->
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- Navbar End -->
</template>

<script setup>
import store from "@/store";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const props = defineProps(["fullName", "login", "role"]);
const route = useRoute();
const path = route.fullPath;
// const role = ref("");

// onMounted(()=>{
//     role.value = store.state.auth.user.data.role[0];

// })
</script>