<template>

  <div class="row">
    <div class="col-8 col-lg-12 col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-md-4">
            <div class="card-header">
              <h3 class="card-title">
                Perfil pessoal
              </h3> 
            </div>
            <div class="card-body">
              <img src="assets/img/userProfile.svg" style="width:200px; height:150px" class="img-fluid" alt="">
              <!-- <button class="btn btn-md btn-primary btn-block">carreagar foto de perfil</button> -->
              <hr>
              <br>
              <ul class="list-unstyled"  >
                <li class="mb-2">
                  <h4 >{{user.fullName}}</h4>
                  <small class="text-center">{{user.alternative_email}}</small>
                </li>
                <li class="mb-2">
                  <strong >Contacto: </strong><span class="">{{user.contact}}</span>
                </li>
                <li class="mb-2"  v-if="user.contact_alternative == null">
                  <strong>Contacto Alternativo: </strong><span class="" style="font-weight:700">-----</span>
                 </li>
                  <li v-else class="mb-2">
                   <strong>Contacto Alternativo: </strong><span class="">{{user.contact_alternative}}</span>
                </li>
                <li class="mb-2">
                  <strong >G&eacute;nero: </strong><span class="t">{{user.gender}} </span>
                </li>
                <!-- <li class="mb-2">
                  <strong >Formado na (o): </strong><span class="text-white">I</span>
                </li> -->
               </ul>
              <router-link v-if="role=='Agent'" class="btn btn-warning btn-md" to="/edit-profile" >Actualizar dados do perfil</router-link>
              <router-link v-if="role=='User'" class="btn btn-warning btn-md" to="/edit-profile" >Actualizar dados do perfil</router-link>
              <router-link v-if="role=='Superadmin'" class="btn btn-warning btn-md" to="/editAdminProfile" >Actualizar dados do perfil</router-link>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card-header">
              <h3 class="card-title">
                Informa&ccedil;&otilde;es adicionais
              </h3>
            </div>
            <div class="card-body">
              <table class="table">
                <tr>
                  <th style="width:20%">Tipo de Conta:</th>
                  <td>
                    <span class="justify-content-between">
                      <span class="badge badge-primary badge-lg m-1" >{{ role }}</span>
                       
                    </span> 
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <span class="justify-content-between">
                      <!-- <span class="badge badge-primary badge-lg" v-for="tur in turmas">
                        {{ tur.name }} - {{ tur.description }}
                      </span> -->
                    </span>
                  </td>
                </tr>
               
                
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
   
  </div>
 
</template>
<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import store from "@/store";
import axios from "axios";
import authHeader from "@/Api/AuthHeader"; 

    const person = ref({});
    const departamento = ref({});
    const user = ref({
      
    })
    const user_id = ref();
    const role = ref()
    

    onMounted(()=>{
      user.value = store.state.auth.user
      user_id.value = store.state.auth.user.user_id
      role.value = store.state.auth.role
    })
    

 
</script>
