import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { MainLayout } from "@/layouts";
  import MyLogin from "@/pages/login/MyLogin.vue";   
import AuthLoading from "@/pages/login/AuthLoading.vue";    
import home_main from "@/components/home_main.vue";   
import addChurch from "@/pages/Church/addChurch.vue"
 import ListChurch from "@/pages/Church/ListChurch.vue" 
 import EditChurch from "@/pages/Church/EditChurch.vue"   
 import addMembers from "@/pages/Members/addMembers.vue"   
import ListMembers from "@/pages/Members/ListMembers.vue"  
import EditMember from "@/pages/Members/EditMember.vue"   
 import MyProfile from "@/pages/profile/MyProfile.vue"     
 import update_password from "@/pages/recover_password/update_password.vue";  
import Recover_password from "@/pages/recover_password/Recover_password.vue"; 
import EditProfile from "@/pages/profile/EditProfile.vue";  
import MyHome from "@/components/MyHome.vue"
import addPosition from "@/pages/Positions/addPosition.vue"
import EditPosition from "@/pages/Positions/EditPosition.vue"
import ListPosition from "@/pages/Positions/ListPosition.vue"
import addGroup from "@/pages/Groups/addGroup.vue"
import EditGroup from "@/pages/Groups/EditGroup.vue"
import ListGroup from "@/pages/Groups/ListGroup.vue"
import Expenses from "@/pages/Expenses/Expenses.vue"
import Events from "@/pages/Events/Events.vue" 
import Contributions from "@/pages/Contributions/Contributions.vue"  
import Purpose from "@/pages/Purpose/Purpose.vue"   
import Statistic from "@/pages/Statistic/Statistic.vue"    
   
 
 
const routes = [
  
  {
    path: "/login",
    name: "login",
    component: MyLogin,
    meta: {
      middleware: "guest",
      title: `Login`,
    },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: Recover_password,
    meta: {
      middleware: "guest",
     },
  },
 


  {
    path: "/",
    component: home_main,
    meta: {
      middleware: "auth",
      requiresAuth:true,
     },
    children: [
      {
        path:"/",
        component:MyHome,
        meta:{
          title:"Dashboard"
        },

        children:[

          {
            path:'/',
            component:Statistic,
            name:"Statistic",
            meta:{
              title:"Statistic"
            }
          },
          {
            path:'/adminProfile',
            component:MyProfile,
            meta:{
              title:'Profile'
            }
          },
          {
            path:'/update_credetials',
            component:update_password
          },
          {
            path:'/editAdminProfile',
            component:EditProfile,
            meta:{
              title:'Edit Prodile'
            }
          },
          {
            path:'/add-churche',
            component:addChurch,
            meta:{
              title:"Church"
            }
          },
          {
            path:'/list-church',
            component:ListChurch,
            meta:{
              title:'List-churche'
            }
          },
          {
            path:'/editChurch/:id',
            name:'editChurch',
            component:EditChurch,
            meta:{
              title:'Edit Church'
            }
          },
          {
            path:'/add-member',
            name:'Addmember',
            component:addMembers,
            meta:{
              title:'Members'
            }

          },
          {
            path:'/list-members',
            name:'listmembers',
            component:ListMembers,
            meta:{
              title:'Member'
            }
          },
          {
            path:'/edit-member/:id',
            name:'editMember',
            component:EditMember,
            meta:{
              title:"Member"
            }
          },


          {
            path:'/add-group/',
            name:'addGroup',
            component:addGroup,
            meta:{
              title:"Group"
            }
          },

          {
            path:'/list-groups/',
            name:'ListGroup',
            component:ListGroup,
            meta:{
              title:"Group"
            }
          },

          {
            path:'/edit-group/:id',
            name:'EditGroup',
            component:EditGroup,
            meta:{
              title:"Group"
            }
          },

          {
            path:'/add-position/',
            name:'AddPosition',
            component:addPosition,
            meta:{
              title:"Position"
            }
          },

          {
            path:'/List-Positions/',
            name:'ListPosition',
            component:ListPosition,
            meta:{
              title:"Position"
            }
          },

          {
            path:'/edit-Positions/:id',
            name:'EditPosition',
            component:EditPosition,
            meta:{
              title:"Position"
            }
          },

          {
            path:'/add-expenses',
            component:Expenses,
            meta:{
              title:'Expenses'
            }
          },

          {
            path:"/events",
            component:Events,
            meta:{
              title:'Events'
            }
          },
          {
            path:'/purpose',
            component:Purpose,
            meta:{
              title:"purpose"
            }
          },
          {
            path:'/contributions/:id',
            component:Contributions,
            name:'contribuition',
            meta:{
              title:"Purpose"
            }
          }
          
        

        ]

      },
      
    ]
  },


   
 

  {
    path:'/auth/google/call-back/:state?',
    component:AuthLoading,
    meta:{
      title:"Hello google"
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});


router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
    if (to.meta.requiresAuth && !isAuthenticated()) {
      // redirect user to the login page
       next('/login');
    }else {
       //allow user to access user his role
          next();
    }
 

  // if (to.meta.middleware == "guest") {
  //   if (store.state.auth.authenticated ) {
  //     next("dashboard");
  //   }
  //   next();
  // } else {
  //   if (store.state.auth.authenticated) {
  //     next();
  //   } else {
  //     next({ name: "login" });
  //   }
  // }
});

function isAuthenticated() {
  if (store.state.auth.authenticated) {
       return true;
    }else{
      return false;
    }
  // return true;
}

function hasUserRole(role){
  if (isAuthenticated()) {
    if (store.state.auth.user.data.role == role ) {
       return true;
   }else{
     return false;
   }
    
  }
    // console.log(store.state.auth.user.data.role[0]);
    // return true;
}
export default router;
