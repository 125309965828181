<template>
     <!-- START ROW -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
           <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="search"
              />
              <button class="btn btn-success" type="submit"><i class="fa fa-search"></i></button>
            
            </div>
            </form>
          </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th>Nome</th>
                    <th scope="col">Email</th>
                     <th scope="col">Contacto</th>
                     </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c, index) in churches"
                    :key="index"
                   >
                    <td>{{ index + 1 }}</td>
                    <td>{{c.name}}</td>
                    <td>{{c.email}}</td>
                     <td>{{c.contact}}</td>

                     <td>

                     <button class="btn btn-light text-primary mr-2 " data-toggle="dropdown" style="background: #e6f4fb; border-radius:6px">
                            <i class="fa fa-ellipsis-h"></i>
                              <div class="dropdown-menu dropdown-menu-right">
                               
                              <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <h6 class="text-dark m-0"><i class="fa fa-edit mr-2 text-data"></i> <router-link class="text-primary" :to="{name:'editChurch', params:{'id':c.id}}" style="font-size:12px; text-decoration:none;"> Editar</router-link></h6>
                              </a>
                               <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <h6 class="text-dark m-0"><i class="fa fa-trash mr-2 text-data"></i> <a href="javascript:void(0)" class="text-primary"  @click="removeProduct(c.id, index)" style="font-size:12px; text-decoration:none;"> Eliminar</a></h6>
                              </a>
                             </div>
                          
                          </button>
                      
                        <!-- <span style="cursor:pointer" v-if="p.category == 'Vender'" @click="SallProduct(p.id)" title="Marcar o produto como vendido" class="fa fa-check text-success "></span>
                        <span style="cursor:pointer" v-else title="Marcar o produtos como arrendado" @click="rentProduct(p.id)" class="fa fa-check text-success "></span>
                        &nbsp;
                        <router-link :to="{name:'editPorduct', params:{'id':p.id}}">
                            <span class="fa fa-edit text-primary"></span>
                        </router-link>
                         &nbsp;
                        <span class="fa fa-trash text-danger"></span>
                        -->
                    </td>
 
              
                   </tr>
                </tbody>
              </table>
             <Page :total="total_page" :model-value="current_page"  :page-size="parseInt(per_page)" @on-change="getChurches"  v-if="total_page"    class="float-right" />

              <div v-if="churches.length == 0" class="text-center">
                Ainda sem informação
              </div>
              <div class="text-center" v-if="loadingData">
                <img
                  src="assets/images/loading.gif"
                  width="45"
                  height="45"
                  alt=""
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END ROW -->
 </template>

<script setup>
import store from "@/store";
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import ChurchStoreModule from "./ChurchStoreModule";

const churches = ref([]);
// const extractos = ref([])
const loadingData = ref(false);
const search = ref();
const router = useRouter();
 const total_page = ref()
 const current_page = ref() 
 const per_page = ref()  
onMounted(() => {
  getChurches();
  // getExtractos()
});

  
 async function getChurches(){

    const resp = await ChurchStoreModule.actions.getChurches();
    churches.value = resp.data.data;
    total_page.value = resp.data.total 
    current_page.value = resp.data.current_page 
    per_page.value = resp.data.per_page  
    console.log(resp.data);
 }

 
async function removeProduct(id, index){
     products.value.splice(index, 1);
       const resp = await ChurchStoreModule.actions.removeProduct(id);
 

}

  watch(search, (newValue, oldValue)=>{
      if (newValue.length >= 4 || oldValue.length >= 4) {
        getChurches()
      }else if(newValue.length == 0 || oldValue.length == 0){
        getChurches()

      }
  });

 
function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}



 

 
</script>


 <style scoped>
.facturaNaoPaga {
  background: #fc5454;
  color: #ffffff;
}
</style>