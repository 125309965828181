import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";


export default {

  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async addExpenses(data) {

        
        try { 
            const resp = await axios.post('/api/expenses', data, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async getAllExpenses(page){
        try {
            const resp = await axios.get('/api/expenses?page='+page, {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    },

    async getLastExpensesInserted(){
        try {
            const resp = await axios.get("/api/lastExpenseInserted", {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    },

    async getCategories(){
        try {
            const resp = await axios.get(`/api/categories`, {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    },

    async getGroups(){
        try {
            const resp = await axios.get(`/api/group`, {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    },

    async getGroupById(id){
        try {
            const resp = await axios.get(`/api/group/${id}`, {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    },

    async updateGroup(data, id){ 

        try {

            let dat = new FormData();


            dat.append("title",data.title)
            dat.append("description",data.description)
            dat.append("id",id)


            const resp = await axios.post(`/api/updateGroup`, dat, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async getTotExpensesbOfEachMonth(){

        try {
            const resp = await axios.get(`/api/getTotExpensesbOfEachMonth/`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }

    },

    async markProductUnvailable(id){
        try {
            const resp = await axios.get(`/Api/api/markProductUnvailable?product_id=${id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async markProductAvailable(id){
        try {
            const resp = await axios.get(`/Api/api/markProductAvailable?product_id=${id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async removeProduct(id){
        try {
            const resp = await axios.get(`/Api/api/removeProduct?product_id=${id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async getMyTotalProduct(id){
        try {
            const resp = await axios.get(`/Api/api/getMyTotalProduct?person_id=${id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async getTotalProductAvailable(id){
        try {
            const resp = await axios.get(`/Api/api/getTotalProductAvailable?person_id=${id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },


    async getTotalProductUnvailable(id){
        try {
            const resp = await axios.get(`/Api/api/getTotalProductUnvailable?person_id=${id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async getTotalProductUnvailableRent(id){
        try {
            const resp = await axios.get(`/Api/api/getTotalProductUnvailableRent?person_id=${id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    }

}

}