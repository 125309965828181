<template>

    <div class="row mb-5">
        <div class="col-lg-3">
              <Bar
              v-if="loaded"
                id="my-chart-id"
                :options="chartOptions"
                :data="chartData"
                 style="height:200px"
            />
        
        </div>

        <div class="col-lg-9" >
            <Line :data="lineData" v-if="loading" :options="LineOptions" style="height:200px" />
        <!-- {{lineData}} -->
         </div>
    
    </div>
  <div class="row">
    <div class="col-lg-4">
        <h3 class="text-center font-14" > <i class="fa fa-calendar mr-1"></i> Últimos 3 Eventos Agendados</h3>
      <div class="card m-b-30">
        <div class="card-body">
           <div v-if="eventData.length > 0" > 

                <div class="col-sm-12 col-xl-12"  v-for="(e, index) in eventData" :key="index">
                <div class="card">
                  <div class="card-heading p-4">
                    <div class="mini-stat-icon-bell float-right">
                      <i class="fa fa-bell bg-warning text-white" ></i>
                    </div>
                    <div>
                      <h5 class="font-14" style="font-weight:700;">{{e.title}}</h5>
                    </div>
                    <small class="mt-4"  style="font-size:13px"><i class="fa fa-calendar mr-1"></i>{{e.date_start}} ate {{e.date_end}}</small>
                    <div>
                    <small class="mt-4"  style="font-size:13px"><i class="fa fa-clock-o mr-1"></i>{{e.start_at}}</small>
                    </div> 
                  </div>
                </div>
              </div>
              </div>


 
                <div v-else class="text-center">
                    <p >Ainda sem eventos</p>
                </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
    <h3 class="text-center font-14" > Últimos 5 Contribuintes</h3>
      <div class="card m-b-30">
        <div class="card-body">
           <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th>Nome</th>
                    <th scope="col">Valor</th>
                     <th scope="col">Propósito</th>
                     <th scope="col">Mês</th>
                     <th scope="col">Igreja</th>
                     <th scope="col">Posição</th>
                     <th scope="col">Data</th>
                    </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c, index) in contribuitionData"
                    :key="index"
                   >
                    <td>{{ index + 1 }}</td>
                    <td>{{c.fullName}}</td>
                    <td>{{moneyFormat(c.amount)}}</td>
                    <td>{{ c.title }}</td>
                    <td >{{c.month}}</td>
                    <td >{{c.name}}</td>
                    <td >{{c.position_title}}</td>
                     <td>
                       {{formatDates(c.created_at)}}
 
                    </td>
  
 
              
                    </tr>
                </tbody>
              </table>
                <Page :total="total_page" :model-value="current_page"  :page-size="parseInt(per_page)" @on-change="getAllContribuition"  v-if="total_page"    class="float-right" />

              <div v-if="contribuitionData.length == 0" class="text-center">
                Ainda sem informação
              </div>
              <div class="text-center" v-if="loadingData">
                <img
                  src="assets/images/loading.gif"
                  width="45"
                  height="45"
                  alt=""
                />
              </div>
              
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, ref } from "vue";
import ContribuitionStoreModule from "../Contributions/ContribuitionStoreModule";
import EventStoreModule from "../Events/EventStoreModule";
import { Bar, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, PointElement, LineElement, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ExpensesStoreModule from "../Expenses/ExpensesStoreModule";
import MemberStoreModule from "../Members/MemberStoreModule";
ChartJS.register(Title, Tooltip, PointElement, LineElement, Legend, BarElement, CategoryScale, LinearScale)

  
 
    const expenses = ref([]);
    const entries = ref([])
 

    const loading = ref(false)
    const loaded = ref(false) 
 
    const chartData = ref(null);

    const months = ref(null);
    
  

    const lineData = ref(null)
    
   

    const LineOptions = ref({
        responsive: true,
        maintainAspectRatio: false
    })

  const chartOptions = ref({
        responsive: true,
       });

    const eventData = ref([]);
    const contribuitionData = ref([]);
     onMounted(()=>{

        getLastTenEvents();
        getLastTenContribuition();
        getTotExpensesbOfEachMonth()
        getAllTotSex()

    })
 
    async function getAllTotSex(){
        loaded.value = false

        try {
            const resp = await MemberStoreModule.actions.getAllTotSex();

             chartData.value = {
                    labels:['Homens','Mulheres'],
                    datasets: [ 
                            { 
                                data: [resp.data.male, resp.data.female], 
                                backgroundColor:['#dc3545', '#6610f2'] 
                            }, 

                            
                        ]
                }

                loaded.value = true;

        } catch (error) {
            
        }
    }
    async function getTotExpensesbOfEachMonth(){
        loading.value = false

        try {

        const resp = await ExpensesStoreModule.actions.getTotExpensesbOfEachMonth();
 

         lineData.value = { 
            
            labels: ['Janeiro', 'Fevereiro', 'Marco', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],

            datasets: [
                {
                    label: 'Despesas',
                    backgroundColor: '#dc3545',
                    borderColor:'#dc3545',
                    data: [parseInt(resp.data.january), parseInt(resp.data.february), parseInt(resp.data.March), parseInt(resp.data.may), parseInt(resp.data.april),
                    parseInt(resp.data.june), parseInt(resp.data.july), parseInt(resp.data.august), parseInt(resp.data.september), parseInt(resp.data.october),parseInt(resp.data.november),
                    parseInt(resp.data.dezember)]
                   
                },

                {
                    label: 'Entradas',
                    backgroundColor:'#28a745',
                    borderColor:'#28a745',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0]
                }

            ] 
        }


        loading.value = true
            
        } catch (error) {
            
        }
       

    }

   
    async function getLastTenEvents(){

        const resp = await EventStoreModule.actions.getLastTenEvent();
        eventData.value = resp.data.data;
        console.log(resp.data);
    }

    async function getLastTenContribuition(){

        const resp = await ContribuitionStoreModule.actions.getLastTenContribuition();
        contribuitionData.value = resp.data.data;
        console.log(resp.data);
    }

function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}

 function formatDates(dateFormat){

      const data = new Date(dateFormat);

      const day = data.getDate();
      const month = data.getMonth() + 1;
      const year = data.getFullYear();
      const hours = data.getHours();
      const minutes = data.getMinutes();
      const seconds = data.getSeconds();

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
  }


</script>
