<template> 
         <div class="card" id="card1" style="padding-right:15px; padding-left:15px">
          
          <div class="row">
  
            <div class="col-lg-12">
              <div class="card-body">
                <form  @submit.prevent="submitAgentForm">
                  <!-- personal User data -->
                  <div  >
                    <h1 class="text-left mb-5 font-24">Actualizar Dados do Perfil</h1>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Nome Completo*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.fullName"
                        />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Data de Nascimento*</label>
                        <input
                          type="date"
                          name="datepicker"
                          class="datepicker-default form-control"
                          id="datepicker1"
                          v-model="userData.birthDate"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Gênero*</label>
                        <select class="form-control" v-model="userData.gender">
                          <option value="Gender">Gênero</option>
                          <option value="Masculino">Masculino</option>
                          <option value="Feminino">Feminino</option>
                          <option value="Outro">Outro</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Estado Civil*</label>
                        <select class="form-control" v-model="userData.marital_status">
                          <option value="Gender">Gênero</option>
                          <option value="Solteiro(a)">Solteiro(a)</option>
                          <option value="Casado(a)">Casado(a)</option>
                          <option value="Divorciado(a)">Divorciado(a)</option>
                          <option value="Viúvo(a)">Viúvo(a)</option>
                          <option value="Outro">Outro</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">BI*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.doc_number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Provincia*</label>
                        <select class="form-control" v-model="userData.province_id" @change="getDistrictsByprovinceId">
                          <option value="">Selecionar</option>
                          <option :value="p.id" v-for="(p, index) in dataProvinces" :key="index">{{p.name}}</option>
                         </select>
                      </div>
                    </div>


                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Distrito*</label>
                        <select class="form-control" v-model="userData.district_id">
                          <option value="">Selecionar</option>
                          <option :value="d.id" v-for="(d, index) in dataDistricts" :key="index">{{d.name}}</option>
                          
                         </select>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Bairro*</label>
                         <input
                          type="text"
                           class="datepicker-default form-control"
                          id="datepicker1"
                          v-model="userData.childhood_location"
                         />
                        </div>
                    </div>


                    <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto*</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                           maxlength="9"
                          v-model="userData.contact"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto Alternativo*</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                           maxlength="9"
                          v-model="userData.contact_alternative"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">whatsapp*</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                           maxlength="9"
                          v-model="userData.whatsapp"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Email*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.alternative_email"
                        />
                      </div>
                    </div>



                    <div class="col-lg-12 col-md-12 col-sm-12">
                       <button
                        type="submit"
                         class="btn btn-success float-right"
                         v-if="loading == false"
                      >
                        Actualizar 
                      </button>
                        <button
                        v-else
                        type="button"
                        disabled="true"
                        class="btn btn-success float-right"
                      >
                        Actualizando...
                      </button>
                    </div>

                        
                  </div>
  
                </form>
                 
              </div>
            </div>
          </div>
        </div>

        <div id="block-screen" v-show="loading == true">
          <div class="process">
                <p class="text-center">Por favor aguarde enquanto carregamos suas informações...</p>
              <img class="img-fluid" src="http://localhost:8080/assets/img/processador_3.gif" alt="">
          </div>
       </div>
  
   
  </template>

<script setup>
import axios from "axios";
import bridge from "../../Api/bridge";
import toastr from "toastr";
import { mapActions, useStore } from 'vuex';
import ChurchStoreModule from "@/pages/Church/ChurchStoreModule";
import MemberStoreModule from "@/pages/Members/MemberStoreModule"; 
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import authHeader from "@/Api/AuthHeader";
import Swal from "sweetalert2";
  
   
      const loadDistricts = ref(true);
      
      const loading = ref(false);
       const userData = ref({
        fullName: "",
        address_location: "",
        alternative_email: "",
        gender: "",
         doc_number: "",
         birthDate: "", 
        childhood_location: "",
        marital_status: "",
        contact: "",
        contact_alternative: "",
        whatsapp:'', 
        province_id: "",
        district_id: "",
        role:'',
        });
 
      const dataProvinces = ref([]);
      const dataDistricts = ref([]); 
    const route = useRoute();
    const storeData = useStore();
        

    onMounted(()=>{
      getProvinces();
      getUserData()
      userData.value.role = store.state.auth.role
     })

     async function getUserData(){
        loading.value = true;
        const resp = await AgentStoreModule.actions.getUserData();
        userData.value = resp.data.data[0]

        if(resp.data.data.length > 0){
          loading.value = false
          }
       getDistrictsByprovinceIdd(resp.data.data[0].province_id)

        console.log(resp.data);
     } 
     async function submitAgentForm(){
      loading.value = true
      const resp = await AgentStoreModule.actions.updateUser(userData.value);
      loading.value = false

    const result = await AgentStoreModule.actions.getUserData();
        // .get("https://anucios.jdesign.co.mz/Api/api/user", {
          storeData.dispatch("auth/updateUserData", result.data);

      if (resp.data.status == true) {
            return Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Dados actulizados com sucesso',
                showConfirmButton: false,
                timer: 1500
            })
      }else{
              return Swal.fire({
                position: 'top-end',
                icon: 'danger',
                title: 'Desculpe-nos ocorreu um erro ao tentar actualizar os dados tente mais tarde...',
                showConfirmButton: false,
                timer: 1500
            })
      }
      }

    async function getProvinces(evt){

        const resp = await ChurchStoreModule.actions.provinces();
        dataProvinces.value = resp.data
        console.log(resp.data);
      
     }

    async function getDistrictsByprovinceId(evt){
      loading.value = true
         const resp = await ChurchStoreModule.actions.districtsByProvinceId(evt.target.value);
        dataDistricts.value = resp.data

        if (resp.data.length > 0) {
          loading.value = false
        }
       
    } 

     async function getDistrictsByprovinceIdd(id){
         const resp = await ChurchStoreModule.actions.districtsByProvinceId(id);
        dataDistricts.value = resp.data
       
    } 

    

    function numberOnly($event) {
      let keycode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keycode < 48 || keycode > 57) && keycode !== 46) {
        $event.preventDefault();
      }
    }
  
</script>
 