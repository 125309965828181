<template> 
           
          <div class="row" >
          <div class="col-xl-12 col-xxl-12 col-sm-12">
         <div class="card" id="card1"  style="padding-right:15px; padding-left:15px">
         
         <div class="row" style="padding-right:15px; padding-left:15px">
         

            <div class="col-lg-12 py-3">
                 <form  @submit.prevent="submitAgentForm">
                  <!-- personal User data -->
                  <div  >
                    <h1 class="text-left mb-5 font-24">Cadastrar Novo Membro</h1>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Nome Completo*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.fullName"
                        />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Data de Nascimento*</label>
                        <input
                          type="date"
                          name="datepicker"
                          class="datepicker-default form-control"
                          id="datepicker1"
                          v-model="userData.birthDate"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Gênero*</label>
                        <select class="form-control" v-model="userData.gender">
                          <option value="Gender">Gênero</option>
                          <option value="Masculino">Masculino</option>
                          <option value="Feminino">Feminino</option>
                          <option value="Outro">Outro</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Estado Civil*</label>
                        <select class="form-control" v-model="userData.marital_status">
                          <option value="">Estado Civil</option>
                          <option value="Solteiro(a)">Solteiro(a)</option>
                          <option value="Casado(a)">Casado(a)</option>
                          <option value="Divorciado(a)">Divorciado(a)</option>
                          <option value="Viúvo(a)">Viúvo(a)</option>
                          <option value="Outro">Outro</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Estado Baptismo*</label>
                        <select class="form-control" v-model="userData.baptized">
                          <option value="Gender">Gênero</option>
                          <option value="Baptizado(a)">Baptizado(a)</option>
                          <option value="Nao Baptizado(a)">Nao Baptizado(a)</option>
                           </select>
                      </div>
                    </div>

                      <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Grupo*</label>
                        <select class="form-control" v-model="userData.group_id">
                          <option value="0">Selecionar o grupo</option>
                          <option :value="g.id" v-for="(g, index) in groups" :key="index">{{g.title}}</option>
                            </select>
                      </div>
                    </div>

                      <div class="col-lg-6 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Posicao*</label>
                        <select class="form-control" v-model="userData.position_id">
                          <option value="0">Selecionar a posicao</option>
                          <option :value="p.id" v-for="(p, index) in positions" :key="index">{{p.title}}</option>
                            </select>
                      </div>
                    </div>

                       <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Ano de Entrada*</label>
                        <select class="form-control" v-model="userData.entry_year">
                          <option value="0">Selecionar o ano</option>
                          <option :value="year" v-for="(year, index) in getYear()" :key="index">{{year}}</option>
                            </select>
                      </div>
                    </div>
 
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Provincia*</label>
                        <select class="form-control" @change="getDistrictsByprovinceId">
                          <option value="">Selecionar</option>
                          <option :value="p.id" v-for="(p, index) in dataProvinces" :key="index">{{p.name}}</option>
                         </select>
                      </div>
                    </div>


                    <div class="col-lg-4 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Distrito*</label>
                        <select class="form-control" v-model="userData.district_id">
                          <option value="">Selecionar</option>
                          <option :value="d.id" v-for="(d, index) in dataDistricts" :key="index">{{d.name}}</option>
                          
                         </select>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Morada*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.address_location"
                        />
                      </div>
                    </div>
 


                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto*</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                           maxlength="9"
                          v-model="userData.contact"
                        />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto Alternativo*</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                           maxlength="9"
                          v-model="userData.contact_alternative"
                        />
                      </div>
                    </div>

                
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Email*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.email"
                        />
                      </div>
                    </div>

                      <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Observação*</label>
                         <textarea
                          type="text"
                           class="datepicker-default form-control"
                          id="datepicker1"
                          v-model="userData.description"
                         ></textarea>
                        </div>
                    </div>



                    <div class="col-lg-12 col-md-12 col-sm-12">
                       <button
                        type="submit"
                         class="btn btn-success float-right"
                         v-if="loading == false"
                      >
                        Submeter 
                      </button>
                        <button
                        v-else
                        type="button"
                        disabled="true"
                        class="btn btn-success float-right"
                      >
                        Submetendo...
                      </button>
                    </div>
 
                  </div>
  
                </form>
                 
              </div>
            </div>
            </div>
            </div>
            </div>

        <div id="block-screen" v-show="loading == true">
        <div class="process">
              <p class="text-center">Por favor aguarde enquanto finalizado o seu acesso...</p>
             <img class="img-fluid" src="http://localhost:8080/assets/img/processador_3.gif" alt="">
        
        </div>
       </div>   

    
   
  </template>

<script setup>
import axios from "axios";
import bridge from "../../Api/bridge";
import toastr from "toastr";
import { mapActions } from 'vuex';
import ChurchStoreModule from "../Church/ChurchStoreModule";
import MemberStoreModule from "./MemberStoreModule"; 
import { onMounted, ref } from "vue";
import Swal from "sweetalert2";
import GroupStoreModule from "../Groups/GroupStoreModule";
import PositionStoreModule from "../Positions/PositionStoreModule";
  
   
      const loadDistricts = ref(true);
      
      const loading = ref(false);
       const userData = ref({
        fullName: "",
        address_location: "",
        email: "",
        gender: "",
        baptized: "",
        group_id: "",
        position_id: "",
        birthDate: "", 
        marital_status: "",
        contact: "",
        contact_alternative: "",
        description:'',
        provice_id: "",
        district_id: "",
        entry_year:''
      });
 
      const dataProvinces = ref([]);
      const dataDistricts = ref([]); 
      const positions = ref([]);  
      const groups = ref([]);  
  
        

    onMounted(()=>{
      getProvinces();
      getGroups();
      getPositions()
     })

 async function getPositions(){
    loading.value = true;
    const resp = await PositionStoreModule.actions.getAllPosition();
    positions.value = resp.data;
    loading.value = false;

    console.log(resp.data);
 }
    async function getGroups(){
    loading.value = true;

    const resp = await GroupStoreModule.actions.getAllGroups();
    groups.value = resp.data;
    loading.value = false;

    console.log(resp.data);
 }

     async function submitAgentForm(){
      loading.value = true
      const resp = await MemberStoreModule.actions.addAgent(userData.value);

      console.log(resp);
       if (resp.data.status === true) {
        loading.value = false
  
        clearInputs()
        return Swal.fire({
          position:'top-end',
          icon:'success',
          title:resp.data.message,
          showConfirmButton:false,
          timer:1500
        })
      }else{
        loading.value = false
        return Swal.fire({
          position:'top-end',
          icon:'error',
          title:resp.data.message,
          showConfirmButton:false,
          timer:1500
        })

      }   
     }


    function getYear(){

      const yearStart = new Date().getFullYear();
      var i = 0, years = [];
      

      for (let index = yearStart; index > 1900; index--) {
         years[i] = index;

          i = i + 1;
      }


      return years;


    }

     function clearInputs(){
        userData.value.fullName= "";
        userData.value.address_location= "";
        userData.value.email= "";
        userData.value.gender= "";
        userData.value.doc_type= "";
        userData.value.doc_number= "";
        userData.value.nactionality= "";
        userData.value.birthDate= "";
        userData.value.childhood_location= "";
        userData.value.marital_status= "";
        userData.value.contact= "";
        userData.value.contact_alternative= "";
        userData.value.whatsapp='';

        userData.value.provice_id= "";
        userData.value.district_id= "";
     }

    async function getProvinces(evt){
        const resp = await ChurchStoreModule.actions.provinces();
        dataProvinces.value = resp.data
      
        console.log(resp.data);
      
     }

    async function getDistrictsByprovinceId(evt){
        loading.value = true;

         const resp = await ChurchStoreModule.actions.districtsByProvinceId(evt.target.value);
        dataDistricts.value = resp.data
          if (resp.data.length > 0) {
          loading.value = false
        }
       
    } 

    

    function numberOnly($event) {
      let keycode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keycode < 48 || keycode > 57) && keycode !== 46) {
        $event.preventDefault();
      }
    }
  
</script>
 