import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";


export default {

  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async addContribuition(data) {

        
        try { 
            const resp = await axios.post('/api/contribuition', data, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async getAllContribuition(page){
        try {
            const resp = await axios.get('/api/contribuition?page='+page, {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    },

    async getLastContribuitionInserted(){
        try {
            const resp = await axios.get("/api/getLastContribuitionInserted", {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    },

    async getLastTenContribuition(){
        try {
            const resp = await axios.get(`/api/getLastTenContribuition`, {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    },

    async getTotContribOfEachMonth(){
        try {
            const resp = await axios.get(`/api/getTotContribOfEachMonth`, {headers:authHeader()});

            return resp;
        } catch (error) {
            return error;
        }
    }, 

    
    

    async updateGroup(data, id){ 

        try {

            let dat = new FormData();


            dat.append("title",data.title)
            dat.append("description",data.description)
            dat.append("id",id)


            const resp = await axios.post(`/api/updateGroup`, dat, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async getMyProduct(user_id){

        try {
            const resp = await axios.get(`/api/getMyProduct/${user_id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }

    },
 

    async removeProduct(id){
        try {
            const resp = await axios.get(`/Api/api/removeProduct?product_id=${id}`, {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },
 
 

}

}