import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
// const URL_API = import.meta.env.VITE_APP_URL_API;
const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async addChurch(data) {
      try {
        let data1 = new FormData();

        data1.append("name", data.name);
        data1.append("address_location", data.address_location);
        data1.append("email", data.email);
        data1.append("contact", data.contact);
        data1.append("alternative_contact", data.alternative_contact);
        data1.append("district_id", data.district_id);

        const resp = await axios.post(URL_API + "church", data1, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async provinces() {
      try {
        const resp = await axios.get(URL_API + "provinces", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async districtsByProvinceId(id) {
      try {
        const resp = await axios.get(URL_API + `district/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getChurches() {
      try {
        const resp = await axios.get(URL_API + `church`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAllChurch() {
      try {
        const resp = await axios.get(URL_API + `getAllChurch`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getChurchById(id) {
      try {
        const resp = await axios.get(URL_API + `church/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async UpdateChurch(data, id) {
      try {
        let dat = new FormData();

        dat.append("name", data.name);
        dat.append("address_location", data.address_location);
        dat.append("email", data.email);
        dat.append("contact", data.contact);
        dat.append("alternative_contact", data.alternative_contact);
        dat.append("district_id", data.district_id);
        dat.append("id", id);
        const resp = await axios.post(URL_API + `updateChurch`, dat, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getMyProduct(user_id) {
      try {
        const resp = await axios.get(URL_API + `getMyProduct/${user_id}`, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async markProductUnvailable(id) {
      try {
        const resp = await axios.get(
          `/Api/api/markProductUnvailable?product_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async markProductAvailable(id) {
      try {
        const resp = await axios.get(
          `/Api/api/markProductAvailable?product_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async removeProduct(id) {
      try {
        const resp = await axios.get(
          `/Api/api/removeProduct?product_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getMyTotalProduct(id) {
      try {
        const resp = await axios.get(
          `/Api/api/getMyTotalProduct?person_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getTotalProductAvailable(id) {
      try {
        const resp = await axios.get(
          `/Api/api/getTotalProductAvailable?person_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getTotalProductUnvailable(id) {
      try {
        const resp = await axios.get(
          `/Api/api/getTotalProductUnvailable?person_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getTotalProductUnvailableRent(id) {
      try {
        const resp = await axios.get(
          `/Api/api/getTotalProductUnvailableRent?person_id=${id}`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },
  },
};
