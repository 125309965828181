<template>

    <!-- Navbar Start -->
    <div class="container bg-white mb-30">
        <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
                <a class="btn d-flex align-items-center justify-content-between bg-white w-100" data-toggle="collapse" href="#navbar-vertical" style="height: 65px; padding: 0 10px;">
                    <h6 class="text-dark m-0"><i class="fa fa-bars mr-2"></i>Menu</h6>
                    <i class="fa fa-angle-down text-dark"></i>
                </a>
                <nav class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light" id="navbar-vertical" style="width: calc(100% - 30px); z-index: 999;">
                    <div class="navbar-nav w-100">
                        <div class="nav-item dropdown dropright">

                          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Igrejas <i class="fa fa-angle-right float-right mt-1"></i></a>
                             <div class="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <router-link to="/add-churche" class="dropdown-item">Adicionar</router-link>
                                <router-link to="/list-church" class="dropdown-item">Listar</router-link>
                             </div>
                        </div>

                        <div class="nav-item dropdown dropright">

                          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Grupos <i class="fa fa-angle-right float-right mt-1"></i></a>
                             <div class="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <router-link to="/add-group" class="dropdown-item">Adicionar</router-link>
                                <router-link to="/list-groups" class="dropdown-item">Listar</router-link>
                             </div>
                        </div>

                        <div class="nav-item dropdown dropright">
                          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Posições<i class="fa fa-angle-right float-right mt-1"></i></a>
                             <div class="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <router-link to="/add-position" class="dropdown-item">Adicionar</router-link>
                                <router-link to="/list-positions" class="dropdown-item">Listar</router-link>
                             </div>
                        </div>

                        <div class="nav-item dropdown dropright">

                          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Membros <i class="fa fa-angle-right float-right mt-1"></i></a>
                             <div class="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <router-link to="/add-member" class="dropdown-item">Adicionar</router-link>
                                <router-link to="/list-members" class="dropdown-item">Listar</router-link>
                             </div>
                        </div>

                       <div class="nav-item dropdown dropright">
                          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Finanças <i class="fa fa-angle-right float-right mt-1"></i></a>
                             <div class="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <router-link to="/add-expenses" class="dropdown-item">Entrada/Saidas</router-link>
                                <!-- <router-link to="/contributions" class="dropdown-item">Contribuicoes</router-link> -->
                                <!-- <router-link to="/list-members" class="dropdown-item">Listar</router-link> -->
                             </div>
                        </div>

                        
                       <div class="nav-item dropdown dropright">
                          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Propositos <i class="fa fa-angle-right float-right mt-1"></i></a>
                             <div class="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <router-link to="/purpose" class="dropdown-item">Adicionar</router-link>
                              </div>
                        </div>



                        <div class="nav-item dropdown dropright">
                          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Eventos <i class="fa fa-angle-right float-right mt-1"></i></a>
                             <div class="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <router-link to="/events" class="dropdown-item">Adicionar</router-link>
                                 <router-link to="/list-members" class="dropdown-item">Listar</router-link>
                             </div>
                        </div>
                        
                    </div>
                </nav>
            </div>

            <div class="col-lg-9">
                <nav class="navbar navbar-expand-lg bg-white navbar-dark py-3 py-lg-0">
                    <a href="" class="text-decoration-none d-block d-lg-none">
                        <span class="h1 text-uppercase text-dark bg-light px-2"> <img src="assets/img/ig software.jpg" style="width:80px; height:80px" class="img-fluid w-100" alt="" /></span>
                        <!-- <span class="h1 text-uppercase text-light bg-primary px-2 ml-n1">Shop</span> -->
                    </a>
                    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div class="navbar-nav mr-auto py-0">
                            <router-link to="/" class="nav-item nav-link active">Dashboard</router-link>
                             
                         </div>
                      

                        <div class="navbar-nav ml-auto py-0 d-none d-lg-block">
                           

                            <div class="nav-item dropdown" v-if="role =='Superadmin'">
                        
                              <a href="" class="nav-link dropdown-toggle text-dark" data-toggle="dropdown" >Minha Conta <i class="fa fa-angle-down"></i></a>
                              <div class="dropdown-menu bg-white rounded-0 border-0 m-0">
                              <a
                                
                                class="btn d-flex align-items-center justify-content-between bg-white w-100"
                                style="height: 45px"
                              >
                                <h6 class="text-dark m-0">
                                  <i class="fa fa-user mr-2 text-primary"></i>
                                  <router-link
                                    class="text-primary"
                                    to="/adminProfile"
                                    style="font-size: 12px; text-decoration: none"
                                  >
                                    Perfil</router-link
                                  >
                                </h6>
                                <i class="fa fa-logout text-dark"></i>
                              </a>
                              <a
                                 class="btn d-flex align-items-center justify-content-between bg-white w-100"
                                style="height: 45px"
                              >
                                <h6 class="text-dark m-0">
                                  <i class="fa fa-lock mr-2 text-primary"></i>
                                  <router-link
                                    class="text-primary"
                                    to="/update_credetials"
                                    style="font-size: 12px; text-decoration: none"
                                  >
                                    Credencias</router-link
                                  >
                                </h6>
                                <i class="fa fa-logout text-dark"></i>
                              </a>
                               <a
                                  
                                  @click="logout"
                                  class="btn d-flex align-items-center justify-content-between bg-white w-100"
                                  style="height: 45px; padding: 0 10px"
                                >
                                  <h6 class="text-dark m-0">
                                    <i class="fa fa-sign-out mr-2 text-danger"></i>
                                    <span style="font-size: 12px"> Terminar Sessão</span>
                                  </h6>
                                  <i class="fa fa-logout text-dark"></i>
                                </a>
                              
                              </div>
                            </div>

                        </div>
                            <a href="" class="btn px-0" v-if="role =='Superadmin'">
                                <i class="fa fa-user text-success"></i>
                                <span class="badge text-dark" style="padding-bottom: 2px; color:#000">{{fullName}}</span>
                            </a> 

                            <a href="" class="btn px-0 ml-3" v-if="role =='Superadmin'">
                                <i class="fa fa-bell text-primary"></i>
                                <span class="badge text-secondary border border-secondary rounded-circle" style="padding-bottom: 2px;">0</span>
                            </a>

                        
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- Navbar End -->

 


    <!-- Featured Start -->
    <div class="container pt-5">
        <div class="row pb-3">

            <div class="col-sm-6 col-xl-3">
                <div class="card">
                  <div class="card-heading p-4">
                    <div class="mini-stat-icon float-right">
                      <i class="fa fa-male bg-primary text-white" ></i>
                    </div>
                    <div>
                      <h5 class="font-16">Total de Homens</h5>
                    </div>
                    <h3 class="mt-4 font-22">0</h3>
                    <div class="progress mt-4" style="height: 4px">
                      <div
                        class="progress-bar bg-primary"
                        role="progressbar"
                        style="width: 75%"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p class="text-muted mt-2 mb-0">
                      Previous period<span class="float-right">75%</span>
                    </p>
                  </div>
                </div>
              </div>


            <div class="col-sm-6 col-xl-3">
                <div class="card">
                  <div class="card-heading p-4">
                    <div class="mini-stat-icon float-right">
                      <i class="fa fa-female bg-info text-white"></i>
                    </div>
                    <div>
                      <h5 class="font-16">Total Mulheres</h5>
                    </div>
                    <h3 class="mt-4 font-22">0</h3>
                    <div class="progress mt-4" style="height: 4px">
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        style="width: 75%"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p class="text-muted mt-2 mb-0">
                      Previous period<span class="float-right">75%</span>
                    </p>
                  </div>
                </div>
              </div>


            <div class="col-sm-6 col-xl-3">
                <div class="card">
                  <div class="card-heading p-4">
                    <div class="mini-stat-icon float-right">
                      <i class="fa fa-arrow-up	 bg-success text-white"></i>
                    </div>
                    <div>
                      <h5 class="font-16">Total Entradas</h5>
                    </div>
                    <h3 class="mt-4 font-22">0</h3>
                    <div class="progress mt-4" style="height: 4px">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        style="width: 75%"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p class="text-muted mt-2 mb-0">
                      Previous period<span class="float-right">75%</span>
                    </p>
                  </div>
                </div>
              </div>


            <div class="col-sm-6 col-xl-3">
                <div class="card">
                  <div class="card-heading p-4">
                    <div class="mini-stat-icon float-right">
                      <i class="fa fa-arrow-down bg-danger text-white"></i>
                    </div>
                    <div>
                      <h5 class="font-16">Total Despesas</h5>
                    </div>
                    <h3 class="mt-4 font-22">{{moneyFormat(TotalExpenses)}}</h3>
                    <div class="progress mt-4" style="height: 4px">
                      <div
                        class="progress-bar bg-danger"
                        role="progressbar"
                        style="width: 75%"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p class="text-muted mt-2 mb-0">
                      Previous period<span class="float-right">75%</span>
                    </p>
                  </div>
                </div>
              </div>
 
        </div>
    </div>
    <div class="container ">
        <RouterView></RouterView>
    </div>

    <!-- Featured End -->


 

 
</template>

<script setup>
import MemberStoreModule from "@/pages/Members/MemberStoreModule";
import ChurchStoreModule from "@/pages/Church/ChurchStoreModule";
import store from "@/store";
import { onMounted, ref } from "vue";
import { mapActions, useStore } from "vuex";
import ExpensesStoreModule from "@/pages/Expenses/ExpensesStoreModule";

  const storeData = useStore()

 const products = ref([]);
 const agents = ref([]);
 const TotalExpenses = ref(0);
 const countRent = ref(0);
 const fullName = ref("");
 const role = ref("");


 onMounted(() => {
  getTotExpensesbOfEachMonth()
  fullName.value = store.state.auth.user.fullName
  role.value = store.state.auth.role

  });

    async function getTotExpensesbOfEachMonth(){
 
        try {

        const resp = await ExpensesStoreModule.actions.getTotExpensesbOfEachMonth();
      console.log(resp.data);

         TotalExpenses.value =  parseFloat(resp.data.january) + parseFloat(resp.data.february) +  parseFloat(resp.data.september) 
            
        } catch (error) {
            console.log(error);
        }
       

    }

 
 
 function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}


 

    async function logout() {
       
      localStorage.removeItem("user");
      storeData.dispatch("auth/logout") 
      window.location.href = "/"
    }

 
 async function getAgents(){

    const resp = await MemberStoreModule.actions.getAgents();
    agents.value = resp.data.data;
    console.log(resp.data.data);
 }

 
</script>
 