<template>
  <!-- Topbar Start -->

  <div class="authincation h-100">
    <div class="container h-100" style="width: 400px; margin-top: 10em">
      <div class="card mx-auto m-2">
        <div class="row h-100 p-5">
          <div class="car col-md-12">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="text-center mb-4">
                    <img
                      src="assets/img/ig software.jpg"
                      class="img-fluid w-100"
                      alt=""
                    />
                  </div>
                  <div class="auth-form">
                    <!-- <h4 class="text-center mb-4">Acesse a sua conta</h4> -->
                    <form @submit.prevent="handleSubmitButton">
                      <div class="form-group">
                        <label class="ml-0"><strong>Email</strong></label>
                        <div class="input-group-append">
                          <input
                            type="text"
                            class="form-control col-lg-10"
                            placeholder="Email"
                            v-model="data.email"
                          />
                          <div class="input-group-text">
                            <span class="fa fa-envelope"></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="ml-0"><strong>Senha</strong></label>
                        <div class="input-group-append">
                          <input
                            type="password"
                            class="form-control col-lg-10"
                            placeholder="Senha"
                            v-model="data.password"
                          />
                          <div class="input-group-text">
                            <span class="fa fa-key"></span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-12"
                      >
                        <div class="form-group">
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="basic_checkbox_1 "
                            />
                            <label
                              class="custom-control-label"
                              for="basic_checkbox_1 "
                              >Lembrar-me</label
                            >
                          </div>
                        </div>
                        <div class="form-group">
                          <router-link
                            to="/forgot-password"
                            class="text-primary"
                            >Esqueceu a senha?
                          </router-link>
                        </div>
                      </div>
                      <div class="text-center mb-2">
                        <button
                          type="submit"
                          class="btn-block"
                          style="
                            background-color: #08535e;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                        >
                          {{ loading == false ? "Acessar" : "Acessando..." }}
                        </button>
                      </div>
                      <!-- <div class="text-center">
                        <a href="http://localhost:8000/auth/google"   class="btn-block" style="
                            background-color: #ced4da;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;"
                           >
                           <img src="assets/img/google.svg" height="15" width="15" alt=""> &nbsp;
                           <span class="text-primary">Entrar com conta google</span>
                         </a>
                      </div> -->
                    </form>
                    <!-- <div class="new-account mt-3">
                      <p>
                        Ainda não fez a sua inscrição?
                        <a href="/inscription" class="text-primary"
                          >Clique aqui</a
                        >
                      </p>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
import Header from "../../components/Header.vue";
import BarMenu from "@/components/BarMenu.vue";
import store from "@/store";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  components: {
    Header,
    BarMenu,
  },
  data() {
    return {
      data: {
        email: "admin@IGsoftware.ac.mz",
        // email: 'info@administrador.co.mz',
        password: "adm1n@1234",
        // password: 'uZU65e1O'
      },

      URL: process.env.VUE_APP_URL,
      URL_API: process.env.VUE_APP_URL_API,
      loading: false,
      fullName: null,
      route: "",
      path: "",
    };
  },

  mounted() {
    this.route = useRoute();
    this.path = this.route.fullPath;
    console.log(this.path);
    if (store.state.auth.authenticated == true) {
      this.fullName = store.state.auth.user.fullName;
    }
  },

  methods: {
    ...mapActions({
      signIn: "auth/login",
    }),

    async handleSubmitButton() {
      this.loading = true;
      // await axios
      // .get("http://127.0.0.1:8000/sanctum/csrf-cookie")
      // .then((response) => {
      await axios
        .get("https://extract.soscommerce.biz/starter/sanctum/csrf-cookie")
        .then((response) => {
          axios
            .post("/api/login", this.data)
            .then(({ data }) => {
              localStorage.setItem("user", JSON.stringify(data));
              toastr.success("Bem vindo!! acedeu com sucesso");

              this.signIn(data);
            })
            .catch((response) => {
              toastr.error(
                "Erro de autenticacao verifique suas credenciais ou contacte o administrador" +
                  response.data.message
              );
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },

    // async handleSubmitButton() {
    //   this.loading = true;
    //   const apiUrl = process.env.VUE_APP_API_URL;

    //   axios
    //     .post(`https://anucios.jdesign.co.mz/Api/api/login`, this.data)
    //     .then(({ data }) => {
    //       if (data.login == false) {
    //                   Swal.fire({
    //                 position:'top-end',
    //                 icon:'success',
    //                 title:'Usuário ou senha inválida',
    //                 showConfirmButton:false,
    //                 timer:1500
    //               })
    //       } else {
    //         localStorage.setItem("user", JSON.stringify(data));
    //                Swal.fire({
    //                 position:'top-end',
    //                 icon:'success',
    //                 title:'Bem vindo!! acedeu com sucesso',
    //                 showConfirmButton:false,
    //                 timer:1500
    //               })

    //         this.signIn(data);
    //         // this.loggedYear(this.data.yearLog);
    //       }
    //     })
    //     .catch((response) => {

    //            Swal.fire({
    //                 position:'top-end',
    //                 icon:'error',
    //                 title:'Erro de autenticacao verifique suas credenciais ou contacte o administrador',
    //                 showConfirmButton:false,
    //                 timer:1500
    //               })

    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
  },
};
</script>
