<template>
    <div id="block-screen">
        <div class="process">
              <p class="text-center">Por favor aguarde enquanto finalizado o seu acesso...</p>
             <img class="img-fluid" src="http://localhost:8080/assets/img/processador_3.gif" alt="">
        
        </div>
       </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";



const route = useRoute();

onMounted(()=>{
    console.log("paras", route.fullPath);

    window.location.href = `http://localhost:8000${route.fullPath}`
 });

</script>

<style scoped>



   #block-screen {
      box-shadow: 2px 2px 20px 1px;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      position: fixed;
      z-index: 999; 
      width: 100%; 
      border-radius: 4px;
      height: 100%;

    }

    #block-screen{
        display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100vh;
      width: 100%;
      transition: height .2s linear;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9999
    }

    .process {
      position: fixed;
      top: 40%;
      left: 47%;
      padding: auto;  
      margin: auto; 
      text-align: center;

    }

    #block-screen {
      background-color: rgba(0, 0, 0, 0.3) !important;
      color: #fff
    }

    .process img {
      width: 160px;
      height: 80px;
    }


</style>