<template>


        <div class="row">
                <!-- <div class="ml-auto pr-2 mb-3"> <button @click="handleOpenModal" class="btn btn-success"> <i class="fa fa-plus"></i> Evento</button></div> -->
           <div class="ml-auto pr-2 mb-3">
                 <button @click="handleOpenModal" class="btn btn-success"><i class="fa fa-plus"></i> Adicionar Proposito</button>

          </div>
       
            <div class="col-xl-12">

             <div class="card m-b-30">
          <div class="card-body">
            
      

              <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="search"
              />
              <button class="btn btn-success" type="submit"><i class="fa fa-search"></i></button>
              
            
            </div>
            </form>
          </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                     <th scope="col">Descricao</th>
                     <th scope="col">Titulo</th>
                        <th scope="col">Data de Criacao</th>
                      </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(p, index) in purposeData"
                    :key="index"
                   >
                    <td>{{ index + 1 }}</td>
                    <td v-if="p.description == null">-------</td>
                    <td v-else>{{p.description}}</td>
                    <td>{{p.title}}</td>
                
                      <td>{{formatDates(p.created_at)}}</td>
                    <td>

                     <button class="btn btn-light text-primary mr-2 " data-toggle="dropdown" style="background: #e6f4fb; border-radius:6px">
                            <i class="fa fa-ellipsis-h"></i>
                              <div class="dropdown-menu dropdown-menu-right">
                                   <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <h6 class="text-dark m-0"><i class="fa fa-trash mr-2 text-data"></i> <router-link  :to="{name:'contribuition', params:{'id':p.id}}" class="text-primary"  style="font-size:12px; text-decoration:none;"> Contribuir</router-link></h6>
                              </a>
                              <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <h6 class="text-dark m-0"><i class="fa fa-edit mr-2 text-data"></i> <a class="text-primary" style="font-size:12px; text-decoration:none;"> Editar</a></h6>
                              </a>
                               <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <h6 class="text-dark m-0"><i class="fa fa-trash mr-2 text-data"></i> <a href="javascript:void(0)" class="text-primary"  @click="removeProduct(p.id, index)" style="font-size:12px; text-decoration:none;"> Eliminar</a></h6>
                              </a> 
                             </div>
                          
                          </button> 
                    </td> 
                   </tr>
                </tbody>
              </table>
          
                <Page :total="total_page" :model-value="current_page"  :page-size="parseInt(per_page)" @on-change="getAllPurpose"  v-if="total_page"    class="float-right" />

              <div v-if="purposeData.length == 0" class="text-center">
                Ainda sem informação
              </div>
              <div class="text-center" v-if="loadingData">
                <img
                  src="assets/images/loading.gif"
                  width="45"
                  height="45"
                  alt=""
                />
              </div>
              
            </div>
          </div>
        </div>
 
            </div>
        </div>
        
  
      <!-- Modal -->
  
    <div v-show="isPurpose"  class="modal " id="fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><i class="fa fa-bell text-warning"></i> Adicionar Novo Proposito</h5>
            <button
              type="button"
              @click="handleCloseModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
                  <form @submit.prevent="submitPurpose">
                           
 
                                <div class="row"> 
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label text-black">Titulo*</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="purpose.title"
                                    />
                                    </div>
                                </div>  
                                     <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="form-label">Descricao*</label>
                                        <textarea
                                        v-model="purpose.description"
                                        type="text"
                                        class="form-control"
                                        ></textarea>
                                    </div>
                                    </div>
                                

                                
                                </div>
                                  <div class="modal-footer">
                                <button v-if="loading == false"  class="btn btn-success">
                                Submeter
                                </button>
                                <button
                                v-else
                                type="button"
                                class="btn btn-info"
                                >
                                Submetendo...
                                </button>
                            </div>
                            </form>
                     </div>

         
            </div>
        </div>
      </div>  

 

    <!-- Modal end> -->



     <!-- Modal -->
  
    <div v-show="isContribuition"  class="modal " id="fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><i class="fa fa-dollar text-warning"></i> Adicionar Contribuicao</h5>
            <button
              type="button"
              @click="handleCloseModalisContribuition"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
                  <form @submit.prevent="submitPurpose">
                           
 
                                <div class="row"> 
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label text-black">Titulo*</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="purpose.title"
                                    />
                                    </div>
                                </div>  
                                     <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="form-label">Descricao*</label>
                                        <textarea
                                        v-model="purpose.description"
                                        type="text"
                                        class="form-control"
                                        ></textarea>
                                    </div>
                                    </div>
                                

                                
                                </div>
                                  <div class="modal-footer">
                                <button v-if="loading == false"  class="btn btn-success">
                                Subtmer
                                </button>
                                <button
                                v-else
                                type="button"
                                class="btn btn-info"
                                >
                                Submetendo...
                                </button>
                            </div>
                            </form>
                     </div>

         
            </div>
        </div>
      </div>  

 

    <!-- Modal end> -->
</template>

<script setup>
import Swal from "sweetalert2";
import { onMounted, ref, watch } from "vue";
 import PurposeStoreModule from './PurposeStoreModule'


    const purpose = ref({
        title:'',
        description:''
    });

    const purposeData = ref([]);

    const loading = ref(false)
    const isPurpose = ref(false) 
    const isContribuition = ref(false)  
     const total_page = ref()
 const current_page = ref() 
 const per_page = ref()  
const search = ref()
    onMounted(()=>{
        getAllPurpose()
    });

    async function getAllPurpose(page=1){

        const resp = await PurposeStoreModule.actions.getAllPurpose(page);

        purposeData.value = resp.data.data;
        total_page.value = resp.data.total 
        current_page.value = resp.data.current_page 
        per_page.value = resp.data.per_page  

     }


  watch(search, (newValue, oldValue)=>{
      if (newValue.length >= 4 || oldValue.length >= 4) {
        getAllPurpose()
      }else if(newValue.length == 0 || oldValue.length == 0){
        getAllPurpose()

      }
  });


    async function submitPurpose(){

        loading.value = true
        const resp = await PurposeStoreModule.actions.addEvent(purpose.value);

        const lastPurpose = await PurposeStoreModule.actions.getLastPurposeInserted();
         purposeData.value.push(lastPurpose.data.data);



         clearInputs()
        if(resp.data.status == true){
            loading.value = false
            return Swal.fire({
            position:'top-end',
            icon:'success',
            title:resp.data.message,
            showConfirmButton:false,
            timer:1500
            })
      }else{
            loading.value = false

          return Swal.fire({
            position:'top-end',
          icon:'error',
          title:resp.data.message,
          showConfirmButton:false,
          timer:1500
        })
     }

    }

    function clearInputs(){
        purpose.value.description = "";
           purpose.value.title = ""
     }

    function handleOpenModal(){
    isPurpose.value = true;
}

function handleCloseModal(){
    isPurpose.value = false;

}

function handleOpenModalisContribuition(){
    isContribuition.value = true;
}

function handleCloseModalisContribuition(){
    isContribuition.value = false;

}


 function formatDates(dateFormat){

      const data = new Date(dateFormat);

      const day = data.getDate();
      const month = data.getMonth() + 1;
      const year = data.getFullYear();
      const hours = data.getHours();
      const minutes = data.getMinutes();
      const seconds = data.getSeconds();

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
  }

</script>

<style scoped>
    .footer-action{ 
        display: flex;
        flex-direction: row;
        margin-left:auto ;
        width: 40px;
    
    }
     .footer-action div {
        width: 20px;
        height: 20px;
        text-align: center;
     
    }

      .footer-action div i {
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        line-height: 20px;
     }
</style>