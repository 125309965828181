<template>


        <div class="row">
                <div class="ml-auto pr-2 mb-3"> <button @click="handleOpenModal" class="btn btn-success"> <i class="fa fa-plus"></i> Contributo</button></div>
          <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
             <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="search"
              />
              <button class="btn btn-success" type="submit"><i class="fa fa-search"></i></button>
            
            </div>
            </form>
          </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th>Nome</th>
                    <th scope="col">Valor</th>
                     <th scope="col">Proposito</th>
                     <th scope="col">Mes</th>
                     <th scope="col">Igreja</th>
                     <th scope="col">Posicao</th>
                     <th scope="col">Data</th>
                    </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c, index) in contribuitionData"
                    :key="index"
                   >
                    <td>{{ index + 1 }}</td>
                    <td>{{c.person_name}}</td>
                    <td>{{moneyFormat(c.amount)}}</td>
                    <td>{{ c.purpose_title }}</td>
                    <td >{{c.month}}</td>
                    <td >{{c.church_name}}</td>
                    <td >{{c.position_title}}</td>
                     <td>
                       

                            <button class="btn btn-light text-primary mr-2 " data-toggle="dropdown" style="background: #e6f4fb; border-radius:6px">
                            <i class="fa fa-ellipsis-h"></i>
                              <div class="dropdown-menu dropdown-menu-right">
                            
                              <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <!-- <h6 class="text-dark m-0"><i class="fa fa-edit mr-2 text-data"></i> <router-link class="text-primary" :to="{name:'editMember', params:{'id':c.id}}" style="font-size:12px; text-decoration:none;"> Editar</router-link></h6> -->
                              </a>
                               <a  class="btn d-flex align-items-center justify-content-between bg-white w-100">
                                <h6 class="text-dark m-0"><i class="fa fa-trash mr-2 text-data"></i> <a href="javascript:void(0)" class="text-primary"  @click="removeProduct(c.id, index)" style="font-size:12px; text-decoration:none;"> Eliminar</a></h6>
                              </a>
                             </div>
                          
                          </button>
                    </td>
  
 
              
                    </tr>
                </tbody>
              </table>
                <Page :total="total_page" :model-value="current_page"  :page-size="parseInt(per_page)" @on-change="getAllContribuition"  v-if="total_page"    class="float-right" />

              <div v-if="contribuitionData.length == 0" class="text-center">
                Ainda sem informação
              </div>
              <div class="text-center" v-if="loadingData">
                <img
                  src="assets/images/loading.gif"
                  width="45"
                  height="45"
                  alt=""
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
        </div>
        
  
      <!-- Modal -->
  
    <div v-show="isEvent"  class="modal " id="fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">  Adicionar Novo Contributo</h5>
            <button
              type="button"
              @click="handleCloseModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
                  <form @submit.prevent="submitContribuition">
                           
 
                                <div class="row"> 
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label text-black">Proposito</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        disabled="true"
                                        v-model="purposeData.title"
                                    />
                                    </div>
                                </div>
 
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label text-black">Procurar Membro*</label>
                                        <select class="form-control" v-model="contribuition.person_id">
                                          <option :value="m.id" v-for="(m, index) in members" :key="index">{{m.fullName}}</option>
                                        </select>
                                    </div>
                                </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label">Igreja*</label>
                                        <select class="form-control" v-model="contribuition.church_id">
                                          <option :value="c.id" v-for="(c, index) in churches" :key="index">{{c.name}}</option>
                                        </select>
                                    </div>
                                </div>


                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label">Posicao*</label>
                                    <select class="form-control" v-model="contribuition.position_id">
                                          <option :value="p.id" v-for="(p, index) in positions" :key="index">{{p.title}}</option>
                                        </select>
                                    </div>
                                </div>


                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label class="form-label">Mes*</label>
                                    <input
                                        type="month"
                                        class="form-control"
                                        v-model="contribuition.month"
                                    />
                                    </div>
                                </div>
                
                                
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="form-label">Valor do Contribuido*</label>
                                          <input
                                        type="text"
                                        class="form-control"
                                        v-model="contribuition.amount"
                                    />
                                    </div>
                                    </div>

                                     <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="form-label">Descricao*</label>
                                        <textarea
                                        v-model="contribuition.description"
                                        type="text"
                                        class="form-control"
                                        ></textarea>
                                    </div>
                                    </div>
                                

                                
                                </div>
                                  <div class="modal-footer">
                                <button v-if="loading == false"  class="btn btn-success">
                                Submeter
                                </button>
                                <button
                                v-else
                                type="button"
                                class="btn btn-info"
                                >
                                Submetendo...
                                </button>
                            </div>
                            </form>
                     </div>

         
            </div>
        </div>
      </div>  

 

    <!-- Modal end> -->
</template>

<script setup>
import Swal from "sweetalert2";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ChurchStoreModule from "../Church/ChurchStoreModule";
import MemberStoreModule from "../Members/MemberStoreModule";
import PositionStoreModule from "../Positions/PositionStoreModule";
import PurposeStoreModule from "../Purpose/PurposeStoreModule";
 import ContribuitionStoreModule from './ContribuitionStoreModule'


    const contribuition = ref({
        "person_id":'',
        "position_id":'',
        "church_id":'',
        "purpose_id":0,
        "month":'',
        "amount":'',
        "description":'',
    });

    const contribuitionData = ref([]);
    const purposeData = ref({});
    const positions = ref([]);
    const churches = ref([]);
    const members = ref([]);
    const loading = ref(false)
    const isEvent = ref(false) 
    const route = useRoute();
     const total_page = ref()
 const current_page = ref() 
 const per_page = ref()  
 const search = ref();

    onMounted(()=>{
        getAllContribuition()
        findPurpose();
        getAllMember();
        findPurpose();
        getAllPosition();
        getChurches();

        contribuition.value.purpose_id = route.params.id;
    });

    async function getAllContribuition(page=1){

        const resp = await ContribuitionStoreModule.actions.getAllContribuition(page);

        contribuitionData.value = resp.data.data;
        total_page.value = resp.data.meta.total 
        current_page.value = resp.data.meta.current_page 
        per_page.value = resp.data.meta.per_page  

        console.log(resp.data.data);
    }

        async function findPurpose(){

        const resp = await PurposeStoreModule.actions.findPurpose(route.params.id);

        purposeData.value = resp.data.data;
    }

  async function getAllPosition(){

    const resp = await PositionStoreModule.actions.getAllPosition();
    positions.value = resp.data;
    console.log(resp.data);
 }
  async function getChurches(){

    const resp = await ChurchStoreModule.actions.getAllChurch();
    churches.value = resp.data;
    console.log(resp.data);
 }

  
 async function getAllMember(){

    const resp = await MemberStoreModule.actions.getMembers();
    members.value = resp.data.data;
    console.log(resp.data.data);
 }



    async function submitContribuition(){

        loading.value = true
        const resp = await ContribuitionStoreModule.actions.addContribuition(contribuition.value);

        const lastContribuition = await ContribuitionStoreModule.actions.getLastContribuitionInserted();
        //  contribuitionData.value.push(lastContribuition.data.data);
        loading.value = false

        getAllContribuition();

         clearInputs()
        if(resp.data.status == true){
            loading.value = false
            return Swal.fire({
            position:'top-end',
            icon:'success',
            title:resp.data.message,
            showConfirmButton:false,
            timer:1500
            })
      }else{
            loading.value = false

          return Swal.fire({
            position:'top-end',
          icon:'error',
          title:resp.data.message,
          showConfirmButton:false,
          timer:1500
        })
     }

    }

    function clearInputs(){
        contribuition.value.person_id = "";
        contribuition.value.position_id = "";
        contribuition.value.church_id = "";
        contribuition.value.description = "";
        contribuition.value.month = ""
        contribuition.value.amount = ""
    }

    function handleOpenModal(){
    isEvent.value = true;
}

function handleCloseModal(){
    isEvent.value = false;

}


function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}

  watch(search, (newValue, oldValue)=>{
      if (newValue.length >= 4 || oldValue.length >= 4) {
        getAllContribuition()
      }else if(newValue.length == 0 || oldValue.length == 0){
        getAllContribuition()

      }
  });


 function formatDates(dateFormat){

      const data = new Date(dateFormat);

      const day = data.getDate();
      const month = data.getMonth() + 1;
      const year = data.getFullYear();
      const hours = data.getHours();
      const minutes = data.getMinutes();
      const seconds = data.getSeconds();

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
  }


</script>

<style scoped>
    .footer-action{ 
        display: flex;
        flex-direction: row;
        margin-left:auto ;
        width: 40px;
    
    }
     .footer-action div {
        width: 20px;
        height: 20px;
        text-align: center;
     
    }

      .footer-action div i {
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        line-height: 20px;
     }
</style>