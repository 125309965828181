<template>

  <!-- Topbar Start -->
    <div class="container-fluid">
      <div class="container">
        <div class="row align-items-center bg-light py-3 d-none d-lg-flex">
            <div class="col-lg-4">
                <a href="" class="text-decoration-none">
                    <span class="h1 text-uppercase text-primary px-2">Multi</span>
                    <span class="h1 text-uppercase text-dark bg-primary ml-n1">Shop</span>
                </a>
            </div>
            <div class="col-lg-4 col-6 text-left">
                <form action="">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search for products">
                        <div class="input-group-append">
                            <span class="input-group-text bg-transparent text-primary">
                                <i class="fa fa-search text-white"></i>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-4 col-6 text-right">
                <p class="m-0">Customer Service</p>
                <h5 class="m-0">+012 345 6789</h5>
            </div>
        </div>
      
      </div>
    </div>
    <!-- Topbar End -->

        <!-- Navbar Start -->
    <div class="container bg-white mb-30">
        <div class="row">
            <div class="col-lg-3 d-none d-lg-block" style="border: 1px solid #eee">
                <a class="btn d-flex align-items-center justify-content-between bg-white w-100" data-toggle="collapse" href="#navbar-vertical" style="height: 65px; padding: 0 30px;">
                    <h6 class="text-dark m-0"><i class="fa fa-bars mr-2"></i>Categorias</h6>
                    <i class="fa fa-angle-down text-dark"></i>
                </a>
                <nav class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light" id="navbar-vertical" style="width: calc(100% - 30px); z-index: 999;">
                    <div class="navbar-nav w-100">
                   
                        <a href="" class="nav-item nav-link">Imoveis</a>
                        <a href="" class="nav-item nav-link">Terrenos</a>
                       
                    </div>
                </nav>
            </div>
            <div class="col-lg-9" style="border: 1px solid #eee">
                <nav class="navbar navbar-expand-lg bg-white navbar-dark py-3 py-lg-0 px-0" >
                    <a href="" class="text-decoration-none d-block d-lg-none">
                        <span class="h1 text-uppercase text-dark bg-light px-2">Multi</span>
                        <span class="h1 text-uppercase text-light bg-primary px-2 ml-n1">Shop</span>
                    </a>
                    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div class="navbar-nav mr-auto py-0">
                            <a href="index.html" class="nav-item nav-link active">Inicio</a>
                            <a href="shop.html" class="nav-item nav-link">Comprar</a>
                            <a href="detail.html" class="nav-item nav-link">Vender</a>
                            <div class="nav-item dropdown">
                                <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Agencia <i class="fa fa-angle-down mt-1"></i></a>
                                <div class="dropdown-menu bg-white rounded-0 border-0 m-0">
                                    <a href="cart.html" class="dropdown-item">Procurar um Agente ou Agencia</a>
                                    <a href="checkout.html" class="dropdown-item">Seja um Agente</a>
                                </div>
                            </div>
                         </div>
                        <div class="navbar-nav ml-auto py-0 d-none d-lg-block">
                            <a href="" class="btn px-0">
                                <i class="fas fa-heart text-primary"></i>
                                <span class="badge text-secondary border border-secondary rounded-circle" style="padding-bottom: 2px;">0</span>
                            </a>
                            <a href="" class="btn px-0 ml-3">
                                <i class="fas fa-shopping-cart text-primary"></i>
                                <span class="badge text-secondary border border-secondary rounded-circle" style="padding-bottom: 2px;">0</span>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- Navbar End -->


    
</template>

<script>
export default {
    
}
</script>